import { useContext, createElement } from 'react';
import { NavbarContext } from '../NavbarContextProvider';
import { styled, View, fromTheme } from 'lib_ui-primitives';

const rc = createElement;

const LockedContainer = styled(View).attrs({ name: 'locked-main-container' })`
    margin-left: ${({ theme }) => (theme.mobile ? 0 : theme.viewMargin + +'px')};
    display: inherit;
    position: absolute;
    left: ${fromTheme('drawerWidth')};
    top: ${fromTheme('headerHeight')};
    width: ${({ theme }) => theme.width - theme.drawerWidth - theme.viewMargin + 'px'};
    height: ${({ theme }) => theme.height - theme.headerHeight + 'px'};
`;

const UnlockedContainer = styled(View).attrs({ name: 'unlocked-main-container' })`
    margin-left: ${({ theme }) => (theme.mobile ? 0 : theme.viewMargin + +'px')};
    max-width: ${fromTheme('width')};
    flex-grow: 1;
    height: ${({ theme }) => theme.height - theme.headerHeight - theme.viewMargin + 'px'};
`;

function MainContainer(props) {
    const { locked } = useContext(NavbarContext);
    if (!props.excludeSideBar && locked) return rc(LockedContainer, null, props.children);
    return rc(UnlockedContainer, null, props.children);
}

export default MainContainer;
