import { useEffect, useState } from 'react';
import lodash from 'lodash';
const { isEqual } = lodash;
import useFormControl from '../../../../hooks/useFormControl';

// A `selection` typically comes from outside of the form. E.g. in the case of Label Printing, it comes from check boxes on the grid.
// This hook provides a means to pass that external data INTO the form, so that any processing rules have access to the selection
// Specifically, we pass the `selection` hash object, the (foreign) namespace/relation, and the routePath.
// The namespace, relation and routePath can be used to retrieve the dbView to pull all the records, after which the selection can be used to filter those record down to the applicable ones.
// This prevents the need for duplicating all the records into memory and passing them around.
//
// `template` is generated by the Print Preview, and is an merger of the full template record, with the property configuration that was configured in blockly on the PrintPreview hNode block.
// As the latter would not be available to any rules, and we already need to generate the merged template here anyway, we might as well pass the whole thing into the form.

export default function useCustomFormUpdater({ template, foreignNamespace, foreignRelation, selection, routePath }) {
    useVirtualFormControl('template', template, 'WorkflowActionList');
    useVirtualFormControl('foreignNamespace', foreignNamespace, 'ShortText');
    useVirtualFormControl('foreignRelation', foreignRelation, 'ShortText');
    useVirtualFormControl('selection', selection, 'NestedObject');
    useVirtualFormControl('routePath', routePath, 'ShortText');
}

// if newValue changes, pass the new value to the form.
function useVirtualFormControl(propertyName, newValue, hNodeType) {
    const { value, setValue } = useFormControl({ hNode: { id: 'bypass', propertyName, hNodeType } });
    //we need to cache the value locally, as it might take a bit before the form context is updated and reflects the new value.
    const [cache, setCache] = useState(value);
    useEffect(() => {
        //use the cache value to compare against, rather than the form context value, as that might lag behind.
        if (!isEqual(cache, newValue)) {
            setCache(newValue);
            setValue(newValue);
        }
    }, [setValue, cache, setCache, newValue]);
}
