import logging from '@sstdev/lib_logging';
import { add, startOfDay, endOfDay } from 'date-fns';
import nativeOnlyProperties from '../nativeOnlyProperties';
import getHostName, { overrideHostname as _overrideHostname, asyncHostname } from './getHostname';
import getBrand from './getBrand';
import getEnvironment from './getEnvironment';
import isDeployed from './isDeployed';
import getPort from './getPort';
import getProtocol from './getProtocol';
import getAuth0Config from './getAuth0Config';

/**
 * This is read only after it is initialized to prevent accidental updates of
 * global config, but it can be intentionally reinitialized.  See overrideHostname()
 * below.
 */

// productionBuild just means webpack built it for production (minified, etc.)
// - it could still be running in dev, staging, etc.
const productionBuild = process.env.NODE_ENV === 'production';
//const productionBuild = true; // Testing with dev url.

let globalConfig;
let initialized = false;
let _groupNumber;
let _featureFlags = [];
function initialize() {
    const hostname = getHostName(productionBuild);
    const environment = getEnvironment(productionBuild);
    const port = getPort(productionBuild);
    const protocol = getProtocol(productionBuild);
    const auth0 = getAuth0Config();

    let config = {
        prohibitedPreviewVerbs: ['cancel'],
        isDeployed: isDeployed(productionBuild),
        brand: getBrand(),
        environment,
        /**The protocol property of the Location interface is a string representing the protocol scheme of the URL, including the final ':' */
        protocol,
        hostname,
        /**a string containing the port number of the URL. If the URL does not contain an explicit port number, it will be set to '' */
        port,
        groupNumber: _groupNumber,
        featureFlags: _featureFlags,
        sensorScanIntervalMilliseconds: 1000,
        logRelease: 'silo_ui-web@' + __PACKAGE_VERSION__, // eslint-disable-line no-undef
        // 0: Error, 1: Warning, 2: Info (default), 3: Debug
        minLogLevel: productionBuild ? 1 : 3,
        minFullTextSearchTermLength: 3,
        logType: productionBuild ? logging.outputType.CONSOLE + logging.outputType.SENTRY : logging.outputType.CONSOLE,
        useOauthAuthentication: false,
        //There is no way to disable this. set it to e.g. 365 (or much more) if you want users to be able to forever stay offline.
        offlineSessionExpirationDays: 60,
        auth0,
        // Name must match title property for useCase.  Also directory must match name with spaces removed.
        customUseCases: {
            // 'Asset Tracking': {},
            // FLAIR: {},
            // 'Item Tracking': {},
            // MetaDataCreator: {},
            // REX: {},
            // Nucleus: {},
            'Lot Management': {
                lowBeaconBatteryLevel: 30
            }
            // 'One Touch Deploy': {},
            // RedBeam: {},
            // 'American Water Asset Tracking': {}
        },
        defaultMetaFields: ['_id'],
        defaultDateRange: {
            startDateTime: startOfDay(add(new Date(), { months: -3 })),
            endDateTime: endOfDay(new Date())
        },
        notificationTimeout: 5000,
        notificationTimeoutFormError: 10000,
        actionElementDebounceMilliseconds: 100,
        searchDebounceMilliseconds: 250,
        onlineSearchDebounceMilliseconds: 1000,
        getRecordsDebounceMilliseconds: 100,
        getRecordsThrottleMilliseconds: 3000,
        maxSecondsOfflineWithoutResync: 30,
        networkErrorRetryMilliseconds: 4000,
        useTempSecurityEndpoint: false,
        backgroundSyncBatchSize: 1000,
        maxRecordsInLimitedSizeCollection: 50000,
        pageSizeForLimitSyncSizeRelations: 200,
        obtrusiveSyncAfterDaysUnsynced: 30,
        auth0RefreshTokenAbsoluteLifetimeDays: 30,
        validActionVerbs: [
            'cancel',
            'change',
            'clear',
            'clearSelection',
            'close',
            'confirm',
            'copy',
            'edit',
            'export',
            'filter',
            // not for database or http data access (use load for those) - for acquiring a resource or value from another part of the application
            'get',
            'import',
            'login',
            'logout',
            'load',
            'mark',
            'new',
            'notify',
            'open',
            'persist',
            'pop',
            'purge',
            'refresh',
            'register',
            'remove',
            'reset',
            'resize',
            'save',
            'select',
            'update',
            'setFilter',
            'startup',
            'stop',
            'submit',
            'upsert',
            'validate',
            'view'
        ],
        validActionResults: ['FAILURE', 'SUCCESS', 'UNNECESSARY', 'COMMIT', 'ROLLBACK'],
        validHeaderProperties: [
            'modifiedBy',
            'modifiedTime',
            'createdBy',
            'createdTime',
            'deletedBy',
            'deletedTime',
            'conflict.status',
            'conflict.reason',
            'conflict.winnerId',
            'correlationId'
        ],
        validRouteCreationVerbs: ['load', 'edit', 'new', 'view'],
        exportTypes: [
            {
                _id: '1',
                description: 'Excel 2007+ XML (XLSX)',
                fileExt: '.xlsx',
                bookType: 'xlsx'
            },
            {
                _id: '2',
                description: 'Excel 97-2004 Workbook (XLS)',
                fileExt: '.xls',
                bookType: 'biff8'
            },
            {
                _id: '3',
                description: 'Comma Separated Values (CSV)',
                fileExt: '.csv',
                bookType: 'csv'
            },
            {
                _id: '4',
                description: 'HTML Document',
                fileExt: '.html',
                bookType: 'html'
            } // , { // This has some formatting problems out of the box.  If demand increases, it might be worth fixing.
            //     _id: '5',
            //     description: 'Rich Text (RTF)',
            //     fileExt: '.rtf',
            //     bookType: 'rtf'
            // }
        ],
        // these are the acceptable types of files to be used for the FileMiddleware
        fileTypes: { image: 'image' },
        socketEndpointLastNode: 'socket.iov3' // v1 & v2 = 'socket.io'
    };

    config = {
        ...config,
        ...nativeOnlyProperties({
            host: `${protocol}//${hostname}${isDeployed() ? '' : ':'}${port}`,
            logRelease: 'siloUiNative@' + __PACKAGE_VERSION__, // eslint-disable-line no-undef
            logType: productionBuild
                ? logging.outputType.BRIDGE
                : logging.outputType.CONSOLE + logging.outputType.BRIDGE,
            requiresSdStorage: true,
            requiresBluetooth: true,
            bluetooth: {
                scanPeriod: 5000
            },
            maxRecordsInLimitedSizeCollection: 20000
        })
    };

    globalConfig = config;
    // Make available outside lib_ui-services
    globalConfig.overrideHostname = overrideHostname;
    globalConfig.setGroupNumber = setGroupNumber;
    globalConfig.asyncHostname = asyncHostname;
    globalConfig.setFeatureFlags = setFeatureFlags;
    Object.freeze(globalConfig);

    initialized = true;
}

function getGlobalConfig() {
    if (!initialized) {
        initialize();
    }
    return globalConfig;
}

export default getGlobalConfig;
export function reInitialize() {
    initialized = false;
}
export function overrideHostname(hostname) {
    _overrideHostname(hostname);
    reInitialize();
}
export function setGroupNumber(groupNumber) {
    _groupNumber = groupNumber;
    reInitialize();
}

export function setFeatureFlags(featureFlags) {
    _featureFlags = featureFlags;
    reInitialize();
}
