export async function PromiseImmediate(func) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                const result = func();
                resolve(result);
            } catch (err) {
                reject(err);
            }
        }, 0);
    });
}
