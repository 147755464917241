// This horrible thing is to avoid a problem where the filters/index.js causes circular
// dependencies.
// Essentially, it provides a layer of indirection so that Node can finish loading all
// the modules without running into anything circular.
let _filters = {};
export default function getFilter(bbFilterType) {
    return _filters[bbFilterType];
}
export function popForTests(filters) {
    _filters = filters;
}
// Make test setup available outside lib_ui-services
getFilter.popForTests = popForTests;

if (!globalThis.__UNIT_TESTING__) {
    setTimeout(async () => {
        _filters = await import('./filters');
    }, 1);
}
