import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import useFormControl from '../../hooks/useFormControl';
import { TextInput, Label, View, fromTheme, ValidationError, styled } from 'lib_ui-primitives';

const rc = React.createElement;

const StyledTextInput = styled(TextInput)`
    flex-grow: 1;
    font-family: 'dotsfont';
    font-size: 10px;
`;

const ColumnLayout = styled(View)`
    flex-direction: column;
    padding: ${fromTheme('viewPadding')};
    background-color: ${fromTheme('backgroundColor')};
    max-width: ${({ theme }) => `${theme.form.entryMaxWidth - 12}px`};
`;

const PasswordWithConfirm = forwardRef(function PasswordWithConfirm(props, ref) {
    let {
        hNode: {
            id,
            placeholder,
            required = false,
            minLength = 0,
            maxLength = 256,
            treePosition: { sequence }
        }
    } = props;
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmTouched, setConfirmTouched] = useState(false);
    const [confirmErrors, setConfirmErrors] = useState([]);

    const { title, setValue, disabled, errors, autoComplete } = useFormControl(props);

    function onPasswordFocused() {
        setPasswordTouched(true);
    }

    function onConfirmFocused() {
        setConfirmTouched(true);
    }

    function onPasswordChanged(text) {
        setPassword(text);
        if (confirm === text) {
            setValue(text);
            setConfirmErrors([]);
        } else {
            setErrorIfNecessary();
        }
    }

    function onConfirmChanged(text) {
        setConfirm(text);
        if (password === text) {
            setValue(text);
            setConfirmErrors([]);
        } else {
            setErrorIfNecessary();
        }
    }

    function setErrorIfNecessary() {
        if (passwordTouched && confirmTouched) {
            setConfirmErrors([`${title} and confirmation must match`]);
        }
    }

    function onBlur() {
        if (password !== confirm) setErrorIfNecessary();
    }

    let elementErrors = [...errors, ...confirmErrors];

    // prettier-ignore
    return rc(ColumnLayout, null,
        rc(Label, { required },
            title,
            rc(StyledTextInput, {
                ref,
                autoComplete,
                id: id + 'firstTry',
                disabled,
                type: 'textbox',
                value: password,
                onChange: onPasswordChanged,
                onFocus: onPasswordFocused,
                onBlur,
                name: 'firstTry',
                placeholder,
                maxLength,
                minLength,
                sequence
            })
        ),
        rc(Label, { required },
            'Confirmation',
            rc(StyledTextInput, {
                id: id + 'secondTry',
                disabled,
                type: 'confirm',
                value: confirm,
                onChange: onConfirmChanged,
                onFocus: onConfirmFocused,
                onBlur,
                name: 'secondTry',
                placeholder,
                maxLength,
                minLength,
                sequence: sequence + 1
            })
        ),
        rc(ValidationError, { errors: elementErrors })
    );
});

PasswordWithConfirm.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
PasswordWithConfirm.displayName = 'PasswordWithConfirm';

export default PasswordWithConfirm;
