import react from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import styled from '../styled';
import fromTheme from '../fromTheme';
import { useTheme } from 'styled-components';

const rc = react.createElement;

const Anchor = styled.a`
    text-decoration: none;
    font-size: ${({ fontSize, theme }) => (fontSize ? fontSize : theme.linkFontSize)}px;
    color: ${fromTheme('linkColor')};
`;

const InlineIcon = styled(Icon)`
    margin-left: 3px;
`;

export default function ExternalLink(props) {
    const {
        url,
        title,
        includeExternalIcon = true,
        style,
        width,
        iconSize = '16px',
        className,
        ...otherAttrs
    } = props || {};
    const theme = useTheme();

    // prettier-ignore
    return rc(Anchor, { target: '_blank', href: url, style, width, className, ...otherAttrs },
        title,
        includeExternalIcon && rc(InlineIcon, { iconSize, fontColor: theme.linkColor }, 'open_in_new')
    );
}

ExternalLink.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string
};
