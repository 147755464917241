import colorConvert from 'color-convert';

// These are colors that could potentially be modified by changing
// the saturation or luminosity without messing up anything.
export const NORMAL_COLORS = Object.freeze({
    beige: '#ffcfb7',
    brightBlue: '#0070f0',
    blue: '#0064A8',
    darkBurntOrange: '#4b1007',
    darkBlue: '#00416A',
    darkRed: '#930B07',
    green: '#009F53',
    highlightBlue: '#D0EDF8',
    highlightGreen: '#D5FCD5',
    highlightPurple: '#EADBFD',
    highlightRed: '#EFBAB3',
    highlightYellow: '#FCF7D2',
    lightBlue: '#06A7E2',
    lightGreen: '#13C762',
    lightPurple: '#A15DFD',
    lightRed: '#FB3740',
    lightYellow: '#F9E873',
    paleBlue: '#EDF9FD',
    purple: '#6423BC',
    red: '#C6110B',
    yellow: '#FDC72F'
});

// Don't try to change the saturation or luminosity on these.
export const CONSTANT_COLORS = Object.freeze({
    black: '#2A2B30',
    darkGray: '#5C5C60',
    lightGray: '#E0E2E4',
    mediumGray: '#C7C8CA',
    redbeamRed: '#F9150D',
    sstBlue: '#007DBD',
    trueBlack: '#000000',
    trueWhite: '#FFFFFF',
    white: '#F2F2F2',
    lightSteelBlue: '#92b6cc',
    darkSteelBlue: '#24638a',
    paleGreen: '#DFF1DF',
    paleRed: '#FDEFEE'
});

// ------------------------------------------------------------
// Example code for altering the saturation or luminosity of the theme
// ------------------------------------------------------------
// These could be given to an end user via a slider or offered via Blockly
// to allow customization of a use case theme.  No time right now to
// explore further.

//Mute colors
const mutePercent = 0.6;
// eslint-disable-next-line no-unused-vars
function mutedColors(colors) {
    const muted = {};
    for (const key in colors) {
        // Convert all colors to HSL
        muted[key] = colorConvert.hex.hsl(colors[key].substring(1));
        // Mute the colors
        muted[key][1] = mutePercent * muted[key][1];
        muted[key][2] = mutePercent * muted[key][2];
        // Convert muted colors back to string
        muted[key] = hslArrayToString(muted[key]);
    }
    return muted;
}

//Lighten colors
const lightenLuminosityPercent = 1.5;
const lightenSaturationPercent = 0.7;
// eslint-disable-next-line no-unused-vars
function lightenColors(colors) {
    const lightened = {};
    // Convert all colors to HSL
    for (const key in colors) {
        lightened[key] = colorConvert.hex.hsl(colors[key].substring(1));
        // lighten the colors
        lightened[key][1] = lightenSaturationPercent * lightened[key][1];
        lightened[key][2] = lightenLuminosityPercent * lightened[key][2];
        // Convert lightened colors back to string
        lightened[key] = hslArrayToString(lightened[key]);
    }
    return lightened;
}

//export const COLORS = { ...mutedColors(NORMAL_COLORS), ...CONSTANT_COLORS };
//export const COLORS = { ...lightenColors(NORMAL_COLORS), ...CONSTANT_COLORS };
// ------------------------------------------------------------

export const COLORS = { ...NORMAL_COLORS, ...CONSTANT_COLORS };
/**
 * These are common among all themes
 * For each color class (alt, error, primary, success, warn, accent-1, and accent-2) We have a normal state (notated
 * with no suffix), a hover state, and a highlight variation.  For primary, we also have a darker variation.
 */
//prettier-ignore
export const COMMON_COLOR_SCHEME = Object.freeze({
    // accent-1
    'accent-1-highlight': COLORS.highlightBlue,
    'accent-1-hover': COLORS.lightBlue,
    'accent-1': COLORS.blue,
    // accent-2
    'accent-2-highlight': COLORS.lightBlue,
    'accent-2-hover': COLORS.blue,
    'accent-2': COLORS.darkBlue,
    // alt
    'alt-highlight': COLORS.highlightPurple,
    'alt-hover': COLORS.lightPurple,
    'alt': COLORS.purple,
    // background
    'background-dark': COLORS.black,
    'background-emphasis': COLORS.mediumGray,
    'background-light': COLORS.lightGray,
    'background-medium': COLORS.darkGray,
    'background-secondary': COLORS.white,
    'background': COLORS.trueWhite,
    // disabled
    'disabled-background': COLORS.mediumGray,
    'disabled-color': COLORS.darkGray,
    // error
    'error-highlight': COLORS.highlightRed,
    'error-hover': COLORS.lightRed,
    'error': COLORS.red,
    // primary
    'primary-dark': COLORS.darkBlue,
    'primary-emphasis': COLORS.paleBlue,
    'primary-highlight': COLORS.highlightBlue,
    'primary-hover': COLORS.lightBlue,
    'primary': COLORS.blue,
    // success
    'success-highlight': COLORS.highlightGreen,
    'success-hover': COLORS.lightGreen,
    'success': COLORS.green,
    // warn
    'warn-highlight': COLORS.highlightYellow,
    'warn-hover': COLORS.lightYellow,
    'warn': COLORS.yellow,
    // lines
    'border-neutral': COLORS.white,
    'border': COLORS.black,
    'divider': COLORS.lightGray,
    'line': COLORS.lightGray,
    'table-row': COLORS.lightGray,
    // texts
    'white-text': COLORS.trueWhite,
    'black-text': COLORS.black,
    'emphasis-text': COLORS.trueBlack,
    'light-accent-text': COLORS.lightSteelBlue,
    'dark-accent-text': COLORS.darkSteelBlue,
    // action available
    'light-action-available': COLORS.lightBlue,
    'dark-action-available': COLORS.brightBlue
});

/**
 * These are specific to each theme
 */
export const BRAND_THEMES = Object.freeze({
    sst: {
        brand: COLORS.sstBlue
    },
    redbeam: {
        brand: COLORS.redbeamRed
    }
});

export const CHART_COLORS = {
    light: [COLORS.black, COLORS.red, COLORS.blue, COLORS.lightBlue, COLORS.highlightRed, COLORS.mediumGray],
    dark: [COLORS.darkGray, COLORS.red, COLORS.blue, COLORS.lightBlue, COLORS.highlightRed, COLORS.mediumGray]
};

function hslArrayToString(hslArray) {
    return `hsl(${hslArray[0]}, ${hslArray[1]}%, ${hslArray[2]}%)`;
}
