import { dbViews, network } from 'lib_ui-services';
import lodash from 'lodash';
const { isEqual } = lodash;
export default {
    verb: 'doingGet',
    namespace: 'item',
    relation: 'item',
    type: 'nextAutoId',
    featureFlag: 'autoIncrementAssetNo',
    //this is the actual logic:
    logic: findNextAutoIncrementNumber
};

const _p = {
    network
};
export const _private = _p;
/**
 *
 * @returns {undefined | number}
 */
async function findNextAutoIncrementNumber({ context }) {
    if (!context) {
        throw new Error('context is missing');
    }
    //rechecking for feature flag as this logic might be called directly, e.g. from copy_item_item__success
    if (!context?.user?.allFeatureFlags?.includes('autoIncrementAssetNo')) return; //return nothing.
    const status = await _p.network.getStatus();
    if (!status.isOnline) return;
    const view = await dbViews.getDbView('item', 'item', 'usedAutoIncrements');

    const filter = {
        // Filter to integers (lokijs handles mixed numbers and strings gracefully with this filter)
        assetNo: { $lt: Number.MAX_SAFE_INTEGER, $gte: 0 }
    };

    // This will attempt to add a filter and sort - if they've already been added and the view is
    // cached, these changed values will be false.
    let changed = view.viewCriteria.addSort('assetNo', true, 'autoIncrementSort');
    changed = view.viewCriteria.addFind(filter, 'autoIncrementFilter') || changed;
    if (changed) {
        let listener, cleanup;
        await new Promise(resolve => {
            listener = () => {
                if (view.lokiView?.filterPipeline.some(f => isEqual(f.val, filter)) ?? false) {
                    resolve();
                }
            };
            view.rebuildListeners.push(listener);
            cleanup = () => view.rebuildListeners.splice(view.rebuildListeners.indexOf(listener), 1);
        });
        cleanup();
    }
    return getNext(view.data);
}

function getNext(array) {
    if (!array?.length) return;
    for (const record of array) {
        if (!record?.meta?.deleted && record?.assetNo) return parseInt(record.assetNo) + 1;
    }
    return;
}
