import { createElement as rc } from 'react';
import useAuth0 from '../../hooks/useAuth0';
import { View, Text, hooks } from 'lib_ui-primitives';

const { useRouter } = hooks;

export default function OAuth() {
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
    const { groupNumber, history } = useRouter();

    if (!isAuthenticated && !isLoading) {
        history.replace(`/g/${groupNumber}/login`);
        loginWithRedirect({
            scope: 'openid profile email offline_access'
        });
    } else {
        if (!isLoading) {
            //it should be rare/non-existent that a user would end up here,
            //as it should be intercepted in Auth0ProviderWithHistory.js which is used in components/App.js
            //But, just in case. better save than sorry; The user is Authenticated. Redirect to root.
            history.push(`/g/${groupNumber}/`);
        }
    }

    return rc(View, null, rc(Text, null, 'Redirecting to Identity Provider'));
}
