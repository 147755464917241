import { createElement as rc, useEffect, createContext } from 'react';
import { Route, Switch } from 'react-router';

import { View, fromTheme, styled, webOnlyStyles, hooks, nativeOnlyStyles } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';

import OAuth from './OAuth';
import Login from './Login';
import NewUserConfirmation from './NewUserConfirmation';
import PasswordReset from './PasswordReset';
import Signup from './Signup';

import Logo, { variants } from '../Logo';
import { setupRulesEngine } from 'lib_ui-core';
import useEventSink from '../../hooks/useEventSink';
import LoadingBoundary from '../contextProviders/LoadingBoundary';
import ReadProvider from '../contextProviders/ReadProvider';
import ActiveRecord from '../contextProviders/ActiveRecord';
import RouteReadOwnershipProvider from '../contextProviders/RouteReadOwnershipProvider';

const { useRouter } = hooks;

// Do not center horizontally on mobile or the keyboard will cut off the bottom.
let Centered = styled(View).attrs({ name: 'centered' })`
    min-height: ${fromTheme('height')};
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ theme: { mobile } }) => (mobile ? 'space-between' : 'center')};
`;

Centered = webOnlyStyles(Centered)`
    box-sizing: border-box;
`;

// Do not constrain the width on mobile view or the login title will have spaces
// at the edge.  Do not use 'unset' as this will break react native.
let Main = styled(View).attrs({ name: 'main' })`
    flex-direction: column;
    justify-content: center;
    flex-grow: 0;
    max-width: ${({ theme: { mobile } }) => (mobile ? '9999px' : '370px')};
`;
// Prevents the credential area from getting squished on native.
Main = nativeOnlyStyles(Main)`
    flex-shrink: 0;
`;
Main.displayName = 'Main';

const StickyFooter = styled(View).attrs({ name: 'stickyFooter' })`
    justify-content: center;
    padding: 12px 0px;
    flex-grow: 0;
    flex-shrink: 1;
`;

/**
 * Used to capture which component in the route currently owns a sensor using a ReadContext
 */
export const RouteReadContext = createContext({
    sensorOwners: {
        RFID: undefined,
        MANUAL: undefined,
        BARCODE: undefined,
        BLE: undefined
    }
});
const ALL_SENSOR_TYPES = [
    constants.sensorTypes.RFID,
    constants.sensorTypes.MANUAL,
    constants.sensorTypes.BARCODE,
    constants.sensorTypes.BLE
];

export default function Unauthenticated() {
    const eventSink = useEventSink();
    useEffect(() => {
        setupRulesEngine({}, eventSink);
    }, [eventSink]);

    const { query, history, location } = useRouter();
    const path = location.pathname;
    useEffect(() => {
        if (query.token && path !== '/confirm') {
            history.replace(`/confirm?token=${query.token}`);
        }
    }, [history, path, query.token]);

    // prettier-ignore
    return rc(Centered, null,
        rc(LoadingBoundary, null,
            rc(Main, null,
                rc(RouteReadOwnershipProvider, { currentRoute: path, readOwnerId: `${path}` },
                    rc(ReadProvider, { id: 'topReadContext', desiredSensorTypes: ALL_SENSOR_TYPES },
                        rc(Switch, {},
                            rc(Route, { path: forAllGroups('/oauth') }, rc(OAuth)),
                            // Note: isNew = true on ActiveRecord is to force a CREATE/POST instead of UPDATE/PATCH
                            rc(Route, { path: forAllGroups('/login') }, rc(ActiveRecord, { namespace: 'security', relation: 'profile', type: 'login', isNew: true }, rc(Login, {}))),
                            rc(Route, { path: forAllGroups('/reset') }, rc(ActiveRecord, { namespace: 'security', relation: 'profile', type: 'reset', isNew: true }, rc(PasswordReset, {}))),
                            rc(Route, { path: forAllGroups('/confirm') },
                                rc(ActiveRecord, { namespace: 'security', relation: 'profile', type: 'confirm', isNew: true, record:{ tokenString: query.token} },
                                rc(NewUserConfirmation, { token: query.token }))
                            ),
                            rc(Route, { path: forAllGroups('/signup') }, rc(ActiveRecord, { namespace: 'security', relation: 'profile', type: 'signup', isNew: true }, rc(Signup, {}))),
                        )
                    ),
                ),
            ),
        ),
        rc(StickyFooter, null,
            rc(Logo, { variant: variants.COLOR, addSlogan:true }),

        )
    );
}

//Hardcoded groups! If we use more than 2 canary groups, this will break (or better yet, needs to be updated)
//However, the likeliness that we will is small, and doesn't outweigh the complexity that would be required to make it dynamic, somehow.
const forAllGroups = path => ['', '/g/0', '/g/1', '/g/2'].map(g => `${g}${path}`);
