let storage;

export function init() {
    storage = {};
}
export function getKey(key, defaultValue) {
    if (storage == null) storage = {};
    return storage[key] ?? defaultValue;
}
export function setKey(key, value) {
    if (storage == null) storage = {};
    storage[key] = value;
}
export function deleteKey(key) {
    if (storage == null) storage = {};
    delete storage[key];
}

export const _private = { storage };
