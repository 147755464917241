import { createElement as ce, memo, useState, useEffect } from 'react';
import { fromTheme, Image, Pressable, styled, Text } from 'lib_ui-primitives';
import { getUseCaseImage, TRANSPARENT_PIXEL } from '../../../images';

const UseCaseItemWrapper = styled(Pressable).attrs((props) => {
        const attrs = {
            name: 'use-case-item-wrapper',
        };
        if (props.active) {
            attrs['data-selected'] = 'true';
        }
        return attrs;
    })`
    margin: 8px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    border-style: solid;
    border-width: 3px;
    border-color: ${props => (props.active ? fromTheme('button', 'primary')(props) : 'transparent')};
    background-color: ${props => (props.active ? 'rgba(19, 124, 189, .1)' : 'transparent')};
`;

const ThumbNail = styled(Image)`
    width: 32px;
    height: 32px;
    margin-right: 8px;
`;

/**
 * @typedef {Object} Props
 * @property {boolean} active
 * @property {(event: React.MouseEvent, useCase: import('../../../types').UseCase) => void} onClick
 * @property {import('../../../types').UseCase} useCase
 */
/**
 *  @type {import("../../../types").FC<Props>}
 */
const UseCaseItem = ({ active, onClick, useCase }) => {
    /**
     * @param {React.MouseEvent} event
     */
    const handleClick = event => {
        event.persist();
        onClick(event, useCase);
    };

    const [src, setImage] = useState(TRANSPARENT_PIXEL);
    useEffect(() => {
        const getImage = async () => {
            const image = await getUseCaseImage(useCase['metaui:useCase'].title);
            setImage(image);
        };
        getImage();
    }, [useCase]);
    const title = `${useCase['metaui:useCase'].title} icon`;

    return ce(
        UseCaseItemWrapper,
        { onClick: handleClick, active },
        ce(ThumbNail, { src, title, alt: title, height: 32, width: 32, onClick: handleClick }),
        ce(Text, null, useCase['metaui:useCase'].title)
    );
};

export default memo(UseCaseItem);
