import { createElement as rc, useCallback } from 'react';
import { View, styled } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';

const SemitransparentBackground = styled(View).attrs({ name: 'semitransparent-background' })`
    ${({ showAsOverlay, theme }) =>
        showAsOverlay &&
        `
        z-index: ${theme.zindex.UnanchoredOverlayBackground};
        position: fixed;
        top: ${theme.headerHeight}px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #88888888;
   `}
`;
SemitransparentBackground.displayName = 'SemitransparentBackground';

export const UnanchoredView = styled(View).attrs({ name: 'unanchored-overlay' })`
    ${({ showAsOverlay, theme }) =>
        showAsOverlay &&
        `
        z-index: ${theme.zindex.UnanchoredOverlayView};
        position: fixed;
        left: ${theme.viewMargin}px;
        right: ${theme.viewMargin}px;
        bottom: ${theme.isIos ? theme.height - theme.keyboardHeight : theme.viewMarginMore}px;

        background-color: ${theme.darkMode ? 'black' : 'white'};
        box-shadow: ${
            theme.darkMode
                ? '8px 11px 13px -2px #000000f0, 4px 23px 39px 0px #5787a9c0'
                : '4px 4px 11px -2px #000000d0, 4px 16px 32px 4px #5787a970'
        }
    `}
`;
UnanchoredView.displayName = 'UnanchoredView';

/**
 * Used to render children in an overlay that sits just above the keyboard on the screen.
 * It looks like a desktop modal, but it only used for mobile (which usually has full
 * screen modals).
 * It will only create the overlay if this is a mobile component, the keyboard is visible
 * and the active prop is true.
 */
export default function UnanchoredOverlay(props) {
    const { mobile, keyboardHeight = 0 } = useTheme();
    const keyboardVisible = keyboardHeight > 0;
    const showAsOverlay = mobile && keyboardVisible && props.active;
    const onClick = useCallback(e => e.preventPropagation?.(), []);
    return rc(
        SemitransparentBackground,
        { showAsOverlay },
        rc(UnanchoredView, { showAsOverlay, onClick }, props.children)
    );
}
