import lodash from 'lodash';
const { omit } = lodash;
import localStorage from '../localStorage';
import encryption from '@sstdev/lib_encryption';
import logging from '@sstdev/lib_logging';
import refreshToken from './refreshToken';
import offlineResilientCommunication from '../offlineResilientCommunication';

const OAUTH_FUNCTIONS = ['logout', 'getAccessTokenSilently'];
/**
 * @type Auth0
 * @property {function():void} [logout]
 * @property {function(Object):Promise<string|Object>} [getAccessTokenSilently]
 */
let _auth0 = {};
export function registerAuth0({ logout, getAccessTokenSilently } = {}) {
    _auth0 = { logout, getAccessTokenSilently };
}

export async function getCurrentSession() {
    const user = await localStorage.getKey('activeSession', '', undefined, false);
    if (user) {
        const session = JSON.parse(user);
        if (session.loginExpiresAt && session.loginExpiresAt < new Date()) {
            await clearCurrentSession();
            return null;
        }
        if (session?.isOauthUser) {
            return { ...session, ..._auth0 };
        }
        return session;
    }
    return null;
}

export async function getCurrentToken(needToken = true) {
    const session = await getCurrentSession();
    if (!session && needToken) {
        await clearCurrentSession();
        await _auth0.logout();
        throw new Error('No active session.');
    }
    if (session?.isOauthUser) {
        try {
            const accessToken = await session.getAccessTokenSilently();
            return `Bearer ${accessToken}`;
        } catch (error) {
            if (error.message === 'Login required') {
                await _auth0.logout();
                throw new Error('Invalid Session');
            }
            throw error;
        }
    }
    if (session?.access_token) {
        //oath style
        //if token has expired, go get a new one
        if (session.accessTokenValidUntil < new Date()) {
            const updatedProfile = refreshToken(session);
            setCurrentSession(updatedProfile);
            return updatedProfile.access_token;
        }
        //otherwise return this one
        return `Bearer ${session.access_token}`;
    } else {
        if (needToken && !session?.tokenKey) {
            throw new Error('No valid token available.');
        }
        //old style, always valid
        return session?.tokenKey;
    }
}

export async function clearCurrentSession() {
    offlineResilientCommunication.enable(false);
    //clear "activeSession" cache from V1:
    await localStorage.deleteKey('token', '', false);
    //clear V2 cache:
    return localStorage.deleteKey('activeSession', '', false);
}

export async function setCurrentSession(tokenPayload) {
    if (typeof tokenPayload !== 'object') {
        throw new Error('Expecting an object token payload');
    }
    let tokenString = JSON.stringify(tokenPayload);

    return localStorage.setKey('activeSession', tokenString, '', false);
}

export async function find(userName, password, tenantId, useCaseId) {
    let offlineUsers = await localStorage.getKey('offlineUsers', '', undefined, false);
    if (!offlineUsers) {
        return null;
    }
    let session = null;
    //if we have the requested user offline
    if (offlineUsers[userName]) {
        const user = offlineUsers[userName];
        const matches = await encryption.bcrypt.compare(password + user.salt, user.password);
        //and if the password matches
        if (!matches) {
            return null;
        }
        const sessions = getActiveTenantSessions(user);
        if (sessions.length === 0) {
            throw new Error('Offline login expired. Please log in online to renew session.');
        }
        //if there is only 1 tenant/usecase
        if (sessions.length === 1) {
            //we can use it
            await setCurrentSession(sessions[0]);
            session = sessions[0];
        } else {
            //user has multiple sessions available offline
            //if the request specified a specific tenant/usecase
            if (tenantId && useCaseId) {
                const requestedUseCaseSession = sessions.find(
                    session => session.activeTenantId === tenantId && session.activeUseCaseId === useCaseId
                );
                if (requestedUseCaseSession) {
                    await setCurrentSession(requestedUseCaseSession);
                    session = requestedUseCaseSession;
                } else {
                    throw new Error('Use case unavailable offline. Please go online, or select a different one.');
                }
            } else {
                //otherwise make the user select one of the offline available
                //by passing the most recent session and forcing tenant selection
                session = {
                    ...sessions[sessions.length - 1],
                    password, // avoid errors for missing passwords
                    needTenantSelection: true
                };
            }
        }
    }
    if (session && session.isOauthUser) {
        session = { ...session, ..._auth0 };
    }
    return session;
}

export async function save(session, password) {
    try {
        const cleanSession = omit(session, OAUTH_FUNCTIONS);
        await setCurrentSession(cleanSession);
        let offlineUsers = (await localStorage.getKey('offlineUsers', '', undefined, false)) || {};
        const salt = Math.floor(Math.random() * 100000).toString();

        const encryptedPassword = await encryption.bcrypt.encrypt(password.toString() + salt);
        let storedUser = offlineUsers[session.userName] || { sessions: [] };
        storedUser.password = encryptedPassword;
        storedUser.salt = salt;
        const currentTenantId = session.activeTenantId;
        const currentUseCaseId = session.activeUseCaseId;
        storedUser.sessions = storedUser.sessions || [];
        const dupSessionIndex = storedUser.sessions.findIndex(
            session => session.activeTenantId === currentTenantId && session.activeUseCaseId === currentUseCaseId
        );
        if (dupSessionIndex >= 0) {
            storedUser.sessions.splice(dupSessionIndex, 1);
        }
        storedUser.sessions.push(cleanSession);
        offlineUsers[session.userName] = storedUser;
        await localStorage.setKey('offlineUsers', offlineUsers, '', false);
    } catch (error) {
        logging.error(error);
        throw error;
    }
}

function getActiveTenantSessions(user) {
    return user.sessions.filter(session => session.loginExpiresAt == null || session.loginExpiresAt > new Date());
}

export async function clearUser(userName) {
    try {
        let offlineUsers = await localStorage.getKey('offlineUsers');
        if (!offlineUsers) {
            return null;
        }
        // eslint-disable-next-line no-unused-vars
        const { [userName]: omit, ...allOtherUsers } = offlineUsers;

        await localStorage.setKey('offlineUsers', allOtherUsers, '', false);
    } catch (error) {
        logging.error(error);
        throw error;
    }
}
