import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, ErrorBoundary, styled, fromTheme } from 'lib_ui-primitives';
import SharedFilterBoundary from '../contextProviders/SharedFilterBoundary';

const Dashboard = styled(View)`
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: flex-start;
    overflow-y: auto;
`;

const Tile = styled(View).attrs({ name: 'Tile' })`
    padding: ${fromTheme('viewPaddingMore')} ${fromTheme('viewPadding')};
    ${({ numWidgets, theme }) =>
        // by fixing the width, we enforce how many widgets go on 1 line
        // by subtracting 2 * viewMargin, we assure there is "viewMargin" space around the widgets
        theme.mobile
            ? // mobile gets 1 widget per line
              `width:calc(100% - ${2 * theme.viewMargin}px);`
            : numWidgets % 3 === 0
            ? // if the number of widgets is exactly dividable by 3, use 3 columns
              `width:calc(33% - ${2 * theme.viewMargin}px);`
            : //otherwise use 2 columns
              `width:calc(50% - ${2 * theme.viewMargin}px);`};

    height: 530px;
    overflow: visible;
`;

function DashboardLayout(props) {
    const { id, title: name = 'dashboard', children, ...otherProps } = props || {};

    // prettier-ignore
    return rc(Dashboard, { name, id, ...otherProps },
        children.map((child, i) =>
            rc(Tile, { key: `widget${i}`, numWidgets: children.length },
                rc(SharedFilterBoundary, {boundaryName: child.props?.hNode?.title}, null,
                    rc(ErrorBoundary, null,
                        child
                    )
                )
            )
        )
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.array,
    hNode: PropTypes.object.isRequired
};

export default DashboardLayout;
