import { createGlobalStyle } from 'styled-components';
import materialIconsNormal from '../../../font/materialIconsNormal.woff2';
import nunitoItalic400ext from '../../../font/nunitoItalic400ext.woff2';
import nunitoItalic400 from '../../../font/nunitoItalic400.woff2';
import nunitoNormal400ext from '../../../font/nunitoNormal400ext.woff2';
import nunitoNormal400 from '../../../font/nunitoNormal400.woff2';
import nunitoNormal700ext from '../../../font/nunitoNormal700ext.woff2';
import nunitoNormal700 from '../../../font/nunitoNormal700.woff2';
import robotoItalic400ext from '../../../font/robotoItalic400ext.woff2';
import robotoItalic400 from '../../../font/robotoItalic400.woff2';
import robotoNormal400ext from '../../../font/robotoNormal400ext.woff2';
import robotoNormal400 from '../../../font/robotoNormal400.woff2';
import robotoNormal700ext from '../../../font/robotoNormal700ext.woff2';
import robotoNormal700 from '../../../font/robotoNormal700.woff2';
import { fromTheme } from 'lib_ui-primitives';

// This imports the fonts needed by the application including
// Roboto Nunito and MaterialIcons (with variations)
// Explanation of using fonts with webpack: https://www.robinwieruch.de/webpack-font
export default createGlobalStyle`

    @font-face {
        font-family: 'Material Icons';
        font-style: normal;
        font-weight: 400;
        src: url(${materialIconsNormal}) format('woff2');
    }
      /* latin-ext */
    @font-face {
        font-family: 'Nunito';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${nunitoItalic400ext}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Nunito';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${nunitoItalic400}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${nunitoNormal400ext}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${nunitoNormal400}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${nunitoNormal700ext}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${nunitoNormal700}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${robotoItalic400ext}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${robotoItalic400}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${robotoNormal400ext}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${robotoNormal400}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${robotoNormal700ext}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${robotoNormal700}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    .material-icons {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }

    .highcharts-color-0 {
        fill: ${({ theme }) => theme.chartColors[0]};
        stroke: ${({ theme }) => theme.chartColors[0]};
    }
    .highcharts-color-1 {
        fill: ${({ theme }) => theme.chartColors[1]};
        stroke: ${({ theme }) => theme.chartColors[1]};
    }

    .highcharts-color-2 {
        fill: ${({ theme }) => theme.chartColors[2]};
        stroke: ${({ theme }) => theme.chartColors[2]};
    }

    .highcharts-color-3 {
        fill: ${({ theme }) => theme.chartColors[3]};
        stroke: ${({ theme }) => theme.chartColors[3]};
    }

    .highcharts-color-4 {
        fill: ${({ theme }) => theme.chartColors[4]};
        stroke: ${({ theme }) => theme.chartColors[4]};
    }

    .highcharts-color-5 {
        fill: ${({ theme }) => theme.chartColors[5]};
        stroke: ${({ theme }) => theme.chartColors[5]};
    }

    .highcharts-background {
        fill: transparent;
    }

    .highcharts-legend-item > text {
        fill: ${fromTheme('defaultFontColor')};
    }

    .highcharts-legend-item:hover text {
        fill: ${fromTheme('disabledFontColor')};
    }

    .highcharts-legend-item-hidden * {
        fill: ${fromTheme('backgroundColorDarker')} !important;
        stroke: ${fromTheme('backgroundColorDarker')} !important;
    }

    .highcharts-axis-labels {
        fill: ${fromTheme('defaultFontColor')};
    }

    .blocklySvg {
        background-color: ${({ theme }) => (theme.darkMode ? '#333' : '#fff')} !important;
    }

    .introjs-tooltip {
        background-color: ${({ theme }) => theme.baseBackgroundColor};
        border-radius: ${({ theme }) => theme.borderRadius}px;
        box-shadow: ${({ theme }) =>
            theme.darkMode
                ? '8px 11px 13px -2px #000000f0, 4px 23px 39px 0px #5787a9c0'
                : '4px 4px 11px -2px #000000d0, 4px 16px 32px 4px #5787a970'};
    }
    .introjs-tooltiptext {
        background-color: ${({ theme }) => theme.backgroundHint};
        font-family: ${({ theme }) => theme.font};
        color: ${({ theme }) => theme.defaultFontColor};
    }
    .introjs-tooltiptext > p {
        font-family: ${({ theme }) => theme.font};
        text-shadow: none;
        margin-block-end: 20px;
        margin-block-start: 4px;
    }
    .introjs-button {
        background-color: ${({ theme }) => theme.button.primary};
        color: ${({ theme }) => theme.button.fontColor};
        border-radius: ${({ theme }) => theme.button.borderRadius}px;
        font-size: ${({ theme }) => theme.button.fontSize}px;
        text-transform: uppercase;
        border: 3px solid transparent;
        font-family: ${({ theme }) => theme.font};
        text-shadow: none;
    }
    .introjs-button:hover {
        background-color: ${({ theme }) => theme.button.primaryHover};
        color: ${({ theme }) => theme.button.fontColor};
    }
    .introjs-button:active {
        background-color: ${({ theme }) => theme.button.primaryHighlight};
        color: ${({ theme }) => theme.button.fontColor};
    }
    .introjs-button:focus {
        background-color: ${({ theme }) => theme.button.primaryHover};
        color: ${({ theme }) => theme.button.fontColor};
    }
    .introjs-arrow.bottom {
        border-top-color: ${({ theme }) => (theme.darkMode ? '#444' : '#ccc')};
        bottom: -16px;
        left: 8px;
    }
    .introjs-arrow.top-right {
        top: -16px;
        right: 8px;
        border-bottom-color: ${({ theme }) => (theme.darkMode ? '#444' : '#ccc')};
    }
    .introjs-arrow {
        border: 8px solid transparent;
    }
    .introjs-arrow.top-middle {
        top: -16px;
        left: 50%;
        margin-left: -5px;
        border-bottom-color: ${({ theme }) => (theme.darkMode ? '#444' : '#ccc')};
    }
    .introjs-arrow.bottom-middle {
        bottom: -16px;
        left: 50%;
        margin-left: -5px;
        border-top-color: ${({ theme }) => (theme.darkMode ? '#444' : '#ccc')};
    }
    .introjs-arrow.bottom-right {
        bottom: -16px;
        right: 8px;
        border-top-color: ${({ theme }) => (theme.darkMode ? '#444' : '#ccc')};
    }
    .introjs-arrow.top {
        top: -16px;
        left: 8px;
        border-bottom-color: ${({ theme }) => (theme.darkMode ? '#444' : '#ccc')};
    }

    .szh-menu {
        background-color: ${fromTheme('menu', 'backgroundColor')};
        border-radius: ${fromTheme('borderRadius')}px;
        margin: ${fromTheme('viewMargin')}px;
    }
    .szh-menu__item {
        color: ${fromTheme('defaultFontColor')};
        padding: 16px;
    }
    .szh-menu__item--hover {
        background-color: ${fromTheme('backgroundColorDarker')};
    }
    .szh-menu__item--hover.szh-menu__item--focusable {
        background-color: inherit;
    }
    .szh-menu__submenu > .szh-menu__item::after {
        content: '';
    }
`;
