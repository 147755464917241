import { useEffect, createElement as rc } from 'react';
import { Route, Switch } from 'react-router';
import { useUserContext, useEventSink } from './hooks';
import { LicenseAgreement, UseCaseRoot, TenantSelection, Unauthenticated } from './components';
import { App, ErrorBoundary, hooks } from 'lib_ui-primitives';
import { configureLogging, registerServiceWorker, sessionStorage, getRouter } from 'lib_ui-services';
import lodash from 'lodash';
const { isEqual } = lodash;
const { useRouter } = hooks;

//All of these paths expect that we do NOT have a logged in user
let userLessPaths = ['/oauth', '/login', '/reset', '/reset/confirmed', '/confirm', '/signup'];
//overload the includes for to some-endswith
userLessPaths.includes = path => userLessPaths.some(p => path.endsWith(p));

const _p = {
    useRouter,
    configureLogging,
    LicenseAgreement,
    UseCaseRoot,
    TenantSelection,
    Unauthenticated
};
export const _private = _p;
/**
 * Responsible for
 * 1. enforce SSL
 * 2. Authentication (& group) verification / redirection
 * 3. guide between "FIXED"/basic routes
 * 4. render use case
 * Note, the actual use case orchestration (rules engine, offline/service worker, persistence setup) happens in `UseCaseRoot`
 */
export default function Backbone() {
    // 1. enforce SSL. Do that here, as the parent (App.js) sets up the react-router Router that we use here
    const router = _p.useRouter();
    router.ensureSslIfDeployed();

    useEffect(() => {
        getRouter.init(router);
    }, [router]);

    useEffect(() => {
        registerServiceWorker.registerRedirectWorker();
    }, []);

    // 2. Authentication, Do that here, as the parent (App.js) sets up the UserContext
    const user = useUserContext();

    const notReady =
        user?.needTenantSelection ||
        user?.needsLicenseAcceptance ||
        (user?.needEulaAcceptance && user?.activeUseCase?.needEulaAcceptance) ||
        (user?.needMlaAcceptance && user?.activeUseCase?.needMlaAcceptance);

    useEffect(() => {
        _p.configureLogging(user);
    }, [user]);

    //3. Switch between "FIXED"/basic routes (for login etc) and "the rest" (As part of the use case)
    useEffect(() => {
        const currentLocation = router.history.location.pathname;
        if (!user || !Object.keys(user).length) {
            //if we are not on a path that is specifically for when we do NOT have a logged in user, correct it
            if (!userLessPaths.includes(currentLocation)) {
                router.redirectToLogin();
            }
            return;
        } else {
            // The rootRoute will be '/' before the tenant is selected because we cannot infer the group without
            // knowing the tenant.
            const rootRoute = user.group
                ? `/g/${user.group?.title?.[1] || user.group}/`
                : `/g/${router.groupNumber || '0'}/`;

            //if we _have_ a user, but they need to select a tenant, or accept a license agreement, display that.
            if (user.needTenantSelection) {
                if (currentLocation !== `${rootRoute}select`) {
                    router.goToLocation(`${rootRoute}select`);
                }
                return;
            } else if (
                (user.needsLicenseAcceptance || user.needEulaAcceptance || user.needMlaAcceptance) &&
                (user.activeUseCase?.needEulaAcceptance || user.activeUseCase?.needMlaAcceptance)
            ) {
                if (currentLocation !== `${rootRoute}licenseAcceptance`) {
                    router.goToLocation(`${rootRoute}licenseAcceptance`);
                }
                return;
            } else {
                //if the user just selected a usecase, and they were already on the correct group
                if ([`${rootRoute}select`, `${rootRoute}licenseAcceptance`].includes(currentLocation)) {
                    //just navigate to the root
                    router.replaceCurrentLocation(rootRoute);
                    return;
                } else if (['/select', '/licenseAcceptance'].some(path => currentLocation.endsWith(path))) {
                    router.ensureProperGroup(user);

                    router.redirectToGroupRoot(user);
                    return;
                }
            }

            //if we have an logged in user, but they navigated (bookmarked) a path that that expects none, redirect to root
            if (userLessPaths.includes(currentLocation)) {
                // group verification / redirection, now that we have a user, we should be able to figure out if their group matches our path
                router.ensureProperGroup(user);
                router.redirectToGroupRoot(user);
                return;
            }
            if (currentLocation === '/') {
                router.redirectToGroupRoot(user);
                return;
            }
            // if redirected from v1 of the app, we might need to add the trailing forward slash ('/')
            // (so that the default route will work)
            if (user.group && currentLocation === `/g/${user.group?.title?.[1] || user.group}`) {
                router.goToLocation(rootRoute);
                return;
            }
            // If the browser has been directed to the UI v1 login, and the user is logged in, go to the root instead
            if (currentLocation === '/sst_login.html') {
                router.goToLocation(rootRoute);
                return;
            }
            //noop if we are at the right group
            router.ensureProperGroup(user);
        }
    }, [user, router]);

    const [subscribe] = useEventSink();

    // Listen for programmatic route change requests and update the app route
    // user-initiated route changes (e.g. nav menu clicks) do NOT go through here.
    useEffect(() => {
        let openActionType = { verb: 'redirect', namespace: 'application', relation: 'route' };
        const unsubscribes = [
            subscribe(openActionType, payload => {
                if (payload.redirectFinishedAction != null) {
                    const unsubscribe = subscribe(
                        { verb: 'navigate', namespace: 'application', relation: 'route', status: 'success' },
                        () => {
                            unsubscribe();
                            payload.redirectFinishedAction();
                        }
                    );
                }
                if (payload.reboundRoute != null) {
                    sessionStorage.setKey(`reboundEvents|${payload.to}`, payload.reboundRoute.reboundEvents);
                    payload.reboundRoute.reboundEvents.forEach(re => {
                        const unsubscribe = subscribe(re, () => {
                            unsubscribe();
                            router.goToLocation(payload.reboundRoute.reboundRoute);
                            // if this is a failure event, then perform the failure action (if defined)
                            if (payload.reboundRoute.failureEvents.some(fe => isEqual(fe, re))) {
                                payload.reboundRoute?.failureAction();
                            }
                            // Wait until after the activeRecord is cleared (see ActiveRecord.js).
                            setTimeout(() => {
                                sessionStorage.deleteKey(`reboundEvents|${payload.to}`);
                            }, 0);
                        });
                    });
                }
                router.goToLocation(payload.to);
            })
        ];
        // unsubscribe during cleanup
        return () => unsubscribes.forEach(u => u());
    }, [subscribe, router]);

    if (!user || !Object.keys(user).length) return rc(App, null, rc(_p.Unauthenticated));
    else {
        const rootRoute = user.group
            ? `/g/${user.group?.title?.[1] || user.group}/`
            : router.groupNumber != null
            ? `/g/${router.groupNumber}/`
            : '/';
        // prettier-ignore
        return rc(ErrorBoundary, null,
            rc(App, null,
                rc(Switch, {},
                    rc(Route, { path: forAllGroups('/select') }, rc(_p.TenantSelection)),
                    rc(Route, { path: forAllGroups('/licenseAcceptance') }, rc(_p.LicenseAgreement)),
                    !notReady && rc(Route, null, rc(_p.UseCaseRoot, { currentRoute: rootRoute }))
                )
            )
        );
    }
}

//Hardcoded groups! If we use more than 2 canary groups, this will break (or better yet, needs to be updated)
//However, the likeliness that we will is small, and doesn't outweigh the complexity that would be required to make it dynamic, somehow.
const forAllGroups = path => ['', '/g/0', '/g/1', '/g/2'].map(g => `${g}${path}`);
