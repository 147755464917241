import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { debounce } = lodash;
import sensorTypes from '../constants/sensorTypes';

let dispatch, scanningTimeoutId;
let currentScanConfig;

const _p = {
    NativeModules: {},
    NativeEventEmitter: () => {},
    handleReads,
    totalLocateAggregate: {},
    throttleInputQueue: []
};

const init = function init(dispatchToRulesEngine) {
    dispatch = dispatchToRulesEngine;
};
export const _private = _p;
export default (NativeModules, NativeEventEmitter, InteractionManager) => {
    _p.NativeModules = NativeModules;
    _p.NativeEventEmitter = NativeEventEmitter;
    _p.InteractionManager = InteractionManager;
    return {
        init,
        startupSensorService,
        stopSensorService,
        scan,
        scanDirect,
        stopScanning,
        locate,
        stopLocating,
        isStarted,
        isReaderAvailable,
        update,
        encode,
        resetReads,
        _private: _p
    };
};
function resetReads() {
    logging.debug('[RFID]: requesting a reset of reads');
    return _p.NativeModules?.RfidReader?.resetReads() ?? Promise.resolve(false);
}

async function isReaderAvailable() {
    const available = (await _p.NativeModules?.RfidReader?.isReaderAvailable()) ?? Promise.resolve(false);
    return available;
}

let serviceStarted = false;
function isStarted() {
    return serviceStarted;
}

function handleReads(tagIds) {
    logging.debug(`[RFID] received ${tagIds.length}`);
    clearTimeout(scanningTimeoutId);
    stopScanning();
    const processedTags = tagIds.map(rawTag => {
        const { scanData: hexTagId, rssi } = rawTag;
        const tag = { ...currentScanConfig, sensorType: sensorTypes.RFID };
        tag['rssi'] = rssi;
        tag['tagId'] = hexTagId;
        tag['_id'] = hexTagId;
        return tag;
    });
    _p.InteractionManager.runAfterInteractions(() => {
        dispatch(processedTags, {
            verb: 'change',
            namespace: 'sensor',
            relation: 'read'
        });
    });
}

const restartLocationing = debounce(() => _p.NativeModules.RfidReader.startLocationing(''), 100, {
    leading: false,
    trailing: true
});

function handleLocationing(tagIds) {
    logging.debug(`[RFID] Received ${JSON.stringify(tagIds)}`);
    tagIds.forEach(rawtag => {
        const { scanData: hexTagId, rssi, relativeDistance } = rawtag;
        const tag = { ...currentScanConfig };
        tag['rssi'] = rssi;
        tag['tagId'] = hexTagId;
        tag['_id'] = hexTagId;
        tag['relativeDistance'] = relativeDistance;
        //if we have a relative distance, dispatch it ASAP.
        //We need real time for locationing
        logging.debug(
            '[RFID]: LOCATETAG ' + tag.asciiTagId + ' | HEX ' + tag.tagId + ' | Distance ' + tag.relativeDistance
        );
        dispatch(tag, {
            verb: 'change',
            namespace: 'sensor',
            relation: 'locate'
        });
    });

    restartLocationing();
}

// Typically called by an on-screen UI affordance rather than a physical button
async function scan() {
    if (!serviceStarted) {
        logging.error("[RFID] A scan was attempted, but the service isn't started.");
        return;
    }
    if (currentScanConfig == null) {
        logging.error('[RFID] A scan was attempted, but no scan configuration is available.');
        return;
    }

    const readerAvailable = await isReaderAvailable();
    if (!readerAvailable) {
        return [];
    }
    _p.NativeModules.RfidReader.startScanning();
    scanningTimeoutId = setTimeout(() => {
        stopScanning();
    }, currentScanConfig.intervalMilliseconds);
}

// Typically called by rules engine, rather than direct user action through UI or physical button
async function scanDirect(_scanConfig = {}) {
    if (!serviceStarted) {
        throw new Error(
            "A scan was attempted, but the service isn't started. If you are using an RFID sled, make sure it is connected, and its battery is charged."
        );
    }
    const scanConfig = { ...currentScanConfig, ..._scanConfig };
    if (!Object.keys(scanConfig).length) {
        throw new Error('A scan was attempted, but no scan configuration is available.');
    }
    const readerAvailable = await isReaderAvailable();
    resetReads();
    let readBuffer = [];
    if (!readerAvailable) {
        return readBuffer;
    } else {
        const interceptReadHandler = tagIds => {
            logging.debug(`[RFID] received ${tagIds.length}`);

            tagIds.forEach(rawTag => {
                const { scanData: hexTagId, rssi } = rawTag;
                const tag = { ...scanConfig, sensorType: sensorTypes.RFID };
                tag['rssi'] = rssi;
                tag['tagId'] = hexTagId;
                tag['_id'] = hexTagId;
                readBuffer.push(tag);
            });
        };
        const deviceEventEmitter = new _p.NativeEventEmitter(_p.NativeModules.RfidReader);
        const listener = deviceEventEmitter.addListener('RfidScansReceived', interceptReadHandler);
        try {
            _p.NativeModules.RfidReader.startScanning();

            await wait(scanConfig.intervalMilliseconds);
            stopScanning();

            for (let tries = 0; tries < 10; tries++) {
                if (readBuffer.length > 0) {
                    break;
                }
                await wait(100);
            }
            return readBuffer;
        } finally {
            logging.debug('[RFID] removing all listeners.');
            listener.remove();
        }
    }
}

async function wait(interval) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, interval);
    });
}

async function startupSensorService(config) {
    // If called twice while processing, return the original promise.
    _p.promiseGate = _p.promiseGate || innerStartupSensorService(config);

    return _p.promiseGate;
}

async function innerStartupSensorService(config) {
    try {
        if (serviceStarted) {
            logging.debug('[RFID] Attempting to start the RFID service, but it is already started.  This is a noop.');
            return serviceStarted;
        }
        if (typeof config.intervalMilliseconds === 'undefined') {
            throw new Error('[RFID] intervalMilliseconds config property is required.');
        }

        if (typeof config.scanType === 'undefined') {
            throw new Error('[RFID] scanType config property is required.');
        }

        if (config.scanType === 'Continuous') {
            throw new Error('[RFID] Continuous scan for Zebra RFID service is not implemented.');
        }

        currentScanConfig = config;

        if (_p.eventListeners?.length > 0) {
            logging.debug('[RFID] removing existing listeners');
            _p.eventListeners.forEach(el => el.remove());
        }
        const deviceEventEmitter = new _p.NativeEventEmitter(_p.NativeModules.RfidReader);
        _p.eventListeners = [
            deviceEventEmitter.addListener('RfidScansReceived', handleReads),
            deviceEventEmitter.addListener('RfidLocatesReceived', handleLocationing),
            deviceEventEmitter.addListener('RfidScanStarted', handleReadStarted),
            //probably due to the hardware, but we seem to receive many stop events when you release the trigger.
            //we only care about it once.
            deviceEventEmitter.addListener(
                'RfidScanStopped',
                debounce(handleReadStopped, 500, { leading: true, trailing: false })
            )
        ];
        logging.debug('[RFID] LISTENERS ADDED');
        await _p.NativeModules.RfidReader.startService();

        _p.promiseGate = undefined;
        serviceStarted = true;
        logging.debug('[RFID]: sensor service finished starting');
        return serviceStarted;
    } catch (err) {
        _p.promiseGate = undefined;
        serviceStarted = false;
        logging.error('[RFID] An error occurred while starting the RFID service.');
        logging.error(err);
        throw err;
    }
}

function stopSensorService() {
    if (!serviceStarted) {
        logging.error('[RFID] Attempted to stop the RFID service, but it is already stopped.');
        return;
    }
    serviceStarted = false;
    try {
        currentScanConfig = undefined;
        stopScanning();
        _p.eventListeners.forEach(el => el.remove());
        _p.eventListeners = [];
        logging.debug('[RFID] LISTENER REMOVED');
        _p.NativeModules.RfidReader.stopService();
    } catch (err) {
        logging.error('[RFID] An error occurred while stopping the RFID service.  It may be in an inconsistent state.');
        throw err;
    }
}

function handleReadStarted() {
    logging.debug('[RFID] RFID Operation Started');
    dispatch(
        { sensorType: sensorTypes.RFID },
        { verb: 'startup', namespace: 'sensor', relation: 'read', status: 'success' }
    );
}

function handleReadStopped() {
    logging.debug('[RFID] RFID Operation Stopped');
    dispatch(
        { sensorType: sensorTypes.RFID },
        { verb: 'stop', namespace: 'sensor', relation: 'read', status: 'success' }
    );
}

function stopScanning() {
    _p.NativeModules.RfidReader.stopScanning();
    if (scanningTimeoutId != null) {
        clearTimeout(scanningTimeoutId);
        scanningTimeoutId = null;
    }
}

async function update(payload) {
    const { power } = payload;
    if (power != null && !isNaN(power)) {
        //getting a response back from Native HAS to go through a promise
        const result = await _p.NativeModules.RfidReader.changePower(Number(power));
        return result;
    }
}

async function locate(payload) {
    const { tagId } = payload;
    if (!serviceStarted) {
        logging.error("[RFID] Locate was attempted, but the service isn't started.");
        return;
    }
    if (tagId == null || tagId === '') {
        logging.error('[RFID] Locate was attempted, but no tag ID is provided.');
        return;
    }
    logging.debug(`[RFID] Starting Locationing for ${tagId}`);
    return _p.NativeModules.RfidReader.startLocationing(tagId);
}

function stopLocating() {
    logging.debug('[RFID] Stopping Locationing');
    // Prevent debounced locationing start from happening after this.
    restartLocationing.cancel();
    _p.NativeModules.RfidReader.stopLocationing();
}

async function encode(payload) {
    const { value, power = 30, target } = payload;
    if (!serviceStarted) {
        return "Encode was attempted, but the service isn't started. If you are using an RFID sled, make sure it is connected, and its battery is charged.";
    }
    if (value == null || value === '') {
        return 'Encode was attempted, but no value is provided.';
    }
    if (target) {
        logging.debug(`[RFID] Attempting to encode ${value} over tag ${target}`);
        const encodeResult = await _p.NativeModules.RfidReader.reEncode(value.toString(), target);
        return encodeResult;
    } else {
        logging.debug(`[RFID] Attempting to encode ${value} at ${power}% power`);
        const encodeResult = await _p.NativeModules.RfidReader.encodeClosest(value.toString(), parseInt(power));
        return encodeResult;
    }
}
