import { useEffect, useState, useContext } from 'react';
import { useMachine } from '@xstate/react';
import { formMachine } from 'lib_ui-services';
import useActiveRecord from '../useActiveRecord';
import useEventSink from '../useEventSink';
import lodash from 'lodash';
const { memoize } = lodash;
import { contexts, hooks } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';
import useGetAutoFocusHNodeId from './useGetAutoFocusHNodeId';
import useSplashRecord from '../useSplashRecord';
const { useRouter } = hooks;

const { FormContext } = contexts;
const _p = {
    useRouter
};
export const _private = _p;

// Debugging tools to see when form state changes (see corresponding stuff at bottom)
// import { useWhatChanged } from '@simbathesailor/use-what-changed';

const emptyErrors = { field: {}, form: [] };
const machineConfig = formMachine();

/**
 * If a FormContext already exists at a higher level, this will do very little except
 * pass that existing context to the consumer.
 * @param {object} hNode
 * @returns FormContext
 */
export default function useFormMachine(hNode) {
    const router = _p.useRouter();
    const { mobile } = useTheme();
    const existingFormContext = useContext(FormContext);
    const alreadyHasFormContext = Object.keys(existingFormContext).length > 0;
    const activeRecord = useActiveRecord();
    const { namespace, relation, record, type } = activeRecord;
    const splashContext = useSplashRecord();
    const [errors, setErrors] = useState(emptyErrors);
    const [subscribe, publish] = useEventSink();
    const [state, send] = useMachine(machineConfig, {
        // eslint-disable-next-line no-undef
        devTools: false // __USE_XSTATE_INSPECTOR__
    });

    // This resets the form state machine back to the start because the activeRecord the
    // form is working on has has changed.
    useEffect(() => {
        if (alreadyHasFormContext) return;
        const { namespace, relation, record, oldRecord, type, isNew } = activeRecord;
        let context = {
            newRecord: { ...record },
            oldRecord: oldRecord || record,
            propertiesToUnset: [],
            isNew,
            publish,
            namespace,
            relation,
            type,
            hNode,
            splashContext,
            routePath: router?.location?.pathname
        };
        if (oldRecord) {
            send({ type: 'restartWithChanges', payload: { context } });
        } else {
            send({ type: 'restart', payload: { context } });
        }
    }, [alreadyHasFormContext, activeRecord, publish, hNode, send, splashContext, router.location.pathname]);

    // Send changes and submissions to the machine
    useEffect(() => {
        if (alreadyHasFormContext) return;
        // prettier-ignore
        const unsubscribes = [
            /* beginChange essentially passes through the change to the rules engine for proper
            merging with the form's model, but it also needs to ensure that the form state
            machine is in the correct (dirty) state as well so the UI can react properly. */
            subscribe({ verb: 'beginChange', namespace, relation, type }, payload => {
                send({ type: 'change', payload });
            }),
            /* put the merged model state (from the rulesengine) into the machine's context state */
            subscribe({ verb: 'change', namespace, relation, type, status: 'success' }, payload => {
                send({ type: 'changeSuccess', payload });
            }),
            subscribe({ verb: 'submit', namespace, relation, type }, () => {
                setErrors(emptyErrors);
                send('submit');
            }),
            subscribe({ verb: 'submit', namespace, relation, type, status: 'failure' }, payload => {
                if (payload.errors) {
                    setErrors(payload.errors);
                }
            }),
            subscribe({ verb: 'validate', namespace, relation, type, status: 'failure' }, payload => {
                if (payload.errors) {
                    setErrors(payload.errors);
                }
                send('validate_failure');
            }),
            subscribe({ verb: 'validate', namespace, relation, type, status: 'success' }, payload => {
                send('validate_success', payload);
            }),
            subscribe({ verb: 'update', namespace, relation, type, status: 'success' }, () => {
                send('update_success');
            }),
            subscribe({ verb: 'update', namespace, relation, type, status: 'failure' }, () => {
                send('update_failure');
            }),
            subscribe({ verb: 'create', namespace, relation, type, status: 'success' }, () => {
                send('create_success');
            }),
            subscribe({ verb: 'create', namespace, relation, type, status: 'failure' }, () => {
                send('create_failure');
            })
        ];
        // Return a cleanup function for the unmount
        return () => {
            unsubscribes.forEach(u => u());
        };
    }, [alreadyHasFormContext, namespace, relation, type, send, subscribe]);

    useEffect(() => {
        if (alreadyHasFormContext) return;
        return subscribe({ verb: 'confirm', namespace, relation, type: 'changeRecord' }, async (payload, context) => {
            // Confirm with the user if abandoning changes.
            if (
                !payload.ignoreChanges &&
                state.matches('edit.dirty') &&
                !['submit', 'remove'].includes(payload.changeContext?.verb ?? [])
            ) {
                return new Promise((resolve, reject) => {
                    publish(
                        {
                            jumpQueue: true,
                            message: 'Are you sure you want to cancel without saving?',
                            okButtonText: 'YES',
                            cancelButtonText: 'NO',
                            okAction: () => {
                                publish(payload, {
                                    ...context,
                                    status: 'success'
                                });
                                resolve();
                            },
                            cancelAction: () => {
                                publish(
                                    {
                                        ...payload,
                                        errors: { field: {}, form: [] }
                                    },
                                    {
                                        ...context,
                                        status: 'failure'
                                    }
                                );
                                reject('The user request to cancel without saving changed data was reconsidered.');
                            }
                        },
                        {
                            verb: 'confirm',
                            namespace: 'application',
                            relation: 'user'
                        }
                    );
                });
            }
            // If the form is not dirty or the user is actually submitting or deleting the record,
            // then proceed without confirmation.
            publish(payload, {
                ...context,
                status: 'success'
            });
            return Promise.resolve();
        });
    }, [alreadyHasFormContext, subscribe, state, publish, namespace, relation]);

    const isDirty = state.matches('edit.dirty');
    const autoFocusHNodeId = useGetAutoFocusHNodeId(
        hNode,
        state.context.newRecord,
        mobile,
        activeRecord?.isNew,
        isDirty
    );

    if (alreadyHasFormContext) return existingFormContext;

    const disabled = state.matches('view') || state.matches('edit.dirty.submitting');
    const submitting = state.matches('edit.dirty.submitting');

    const formContext = memoize(
        (isDirty, submitting, disabled, oldRecord, newRecord, formErrors, errorsByPath, state) => {
            return {
                isDirty,
                submitting,
                disabled,
                oldRecord,
                newRecord,
                formErrors,
                errorsByPath,
                state,
                autoFocusHNodeId
            };
        },
        (isDirty, submitting, disabled, oldRecord, newRecord, formErrors, errorsByPath, autoFocusHNodeId) =>
            JSON.stringify({
                isDirty,
                submitting,
                disabled,
                oldRecord,
                newRecord,
                formErrors,
                errorsByPath,
                autoFocusHNodeId
            })
    );

    const result = formContext(
        isDirty,
        submitting,
        disabled,
        record,
        state.context.newRecord,
        errors.form,
        errors.field,
        state,
        autoFocusHNodeId
    );

    // Debugging tools to see when the form state changes:
    // useWhatChanged(
    //     [
    //         result.isDirty,
    //         result.submitting,
    //         result.disabled,
    //         result.oldRecord,
    //         result.newRecord,
    //         result.formErrors,
    //         result.errorsByPath,
    //         result.autoFocusHNodeId
    //     ],
    //     'isDirty, submitting, disabled, oldRecord, newRecord, formErrors, errorsByPath, autoFocusHNodeId'
    // );

    return result;
}
