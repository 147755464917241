import { constants } from 'lib_ui-services';

export default {
    verb: 'willMark',
    namespace: 'item',
    relation: 'item',
    description: 'Assure markText is at least 8 characters, or leftPad with 0s',
    useCaseIds: [constants.useCaseIds.FLAIR],
    logic: willMark
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
function willMark({ data, context }) {
    const { featureFlags: _featureFlags, user: { allFeatureFlags } = {} } = context;
    const featureFlags = [].concat(_featureFlags).concat(allFeatureFlags);
    if (featureFlags.includes('forFOG')) {
        if (data?.markText?.length < 6) {
            data.originalMarkText = data.markText;
            data.markText = data.markText.padStart(6, '0');
        }
    } else {
        if (data?.markText?.length < 8) {
            data.originalMarkText = data.markText;
            data.markText = data.markText.padStart(8, '0');
        }
    }
    return data;
}
