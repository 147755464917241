import { useState, useEffect } from 'react';
import lodash from 'lodash';
const { get } = lodash;
const { isEqual } = lodash;
import logging from '@sstdev/lib_logging';
import useSelectedFormEntry from './useSelectedFormEntry';
import useFormContext from '../../../../hooks/useFormContext';
import { emptyDesignTemplate, selectATemplate, standardColumnDefinition } from '../constants';

const _p = {
    useFormContext,
    useSelectedFormEntry
};
export const _private = _p;
export default function useLabelTemplate(props) {
    const {
        hNode: { namespace, relation },
        children
    } = props || { hNode: {} };
    const inTemplateDesignMode = namespace === 'print' && relation === 'template';

    const defaultTemplate = { actions: inTemplateDesignMode ? emptyDesignTemplate : selectATemplate };
    const [template, setTemplate] = useState(defaultTemplate.actions);
    const { newRecord = {} } = _p.useFormContext();

    //get the field definitions
    const selectedTemplate = _p.useSelectedFormEntry({ namespace: 'print', relation: 'template' }, defaultTemplate);

    //get the property mappings (from blockly)
    const childrenOrDefault = children?.length ? children : standardColumnDefinition;
    const barcodeProperty = get(
        //barcode _should_ be at index 1
        childrenOrDefault.find(col => get(col, 'props.hNode.hNodeType', '') === 'Barcode'),
        'props.hNode.propertyName'
    );
    const additionalProperties = childrenOrDefault
        .filter(col => get(col, 'props.hNode.hNodeType', '') !== 'Barcode')
        .map(col => get(col, 'props.hNode.propertyName'));

    //if either definitions on mappings change, merge, and generate a new template
    useEffect(() => {
        const result = merge(newRecord?.actions || selectedTemplate.actions, [
            barcodeProperty,
            ...additionalProperties
        ]);
        if (!isEqual(result, template)) {
            setTemplate(result);
        }
    }, [template, selectedTemplate, newRecord, barcodeProperty, additionalProperties]);

    return { template, inTemplateDesignMode };
}

export function merge(template, properties) {
    return template.map((line, index) => {
        if (typeof line.fixedText !== 'undefined' || line.workflowAction === 'update_print_template') {
            //Fixed Text lines are passed as normal
            return {
                ...line,
                hNodeType: 'FixedTextContent'
            };
        }
        if (typeof line['print:field'] !== 'undefined' || line.workflowAction === 'update_print_field') {
            if (!line['print:field']) {
                //they still need to configure it.
                //Default to field 2 (usually description)
                return {
                    ...line,
                    hNodeType: 'ReferencedContent',
                    propertyName: properties[1] || 'title'
                };
            }
            const [fieldNum] = line['print:field'].title.split(',');
            if (isNaN(fieldNum)) {
                logging.error(
                    `print:field of line ${index} is set to ${line['print:field'].title}, which does not have a numeric title.`
                );
                return {
                    hNodeType: 'FixedTextContent',
                    isError: true,
                    fixedText: 'ERROR IN CONFIGURATION'
                };
            }
            const fieldIndex = Number(fieldNum) - 1; // fields are 1-based for ease of end user.
            if (fieldIndex < 0 || fieldIndex >= properties.length) {
                //the user chose a field index that is not provided as data for this relation
                //which means we'll always just print out a (fixed) blank line.
                return {
                    hNodeType: 'FixedTextContent',
                    fixedText: ' '
                };
            }
            return {
                ...line,
                hNodeType: 'ReferencedContent',
                propertyName: properties[fieldIndex] || 'title'
            };
        }
        //only option left is barcode
        return {
            ...line,
            hNodeType: 'BarcodeContent',
            propertyName: properties[0]
        };
    });
}
