import sendToPrinter from './sendToPrinter';
import ZPL from './generateZPL';
import { getGridData } from '../namespace/relation/bulk/utilities';

const _p = {
    sendToPrinter: sendToPrinter.send,
    getGridData
};
export const _private = _p;

export default {
    verb: 'doingCreate',
    namespace: 'print',
    relation: 'job',
    description: 'Print Labels',
    //this is the actual logic:
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'print',
                relation: 'printer',
                type: 'find'
            }
        },
        {
            context: {
                verb: 'get',
                namespace: 'print',
                relation: 'size',
                type: 'find'
            }
        }
    ],
    logic: doingCreate
};

async function doingCreate({ data, prerequisiteResults }) {
    const { newRecord = {} } = data;
    const {
        foreignNamespace,
        foreignRelation,
        template, // - the template to print (including properties)
        selection,
        'print:printer': printerRef,
        'print:size': sizeRef,
        routePath
    } = newRecord;
    const printers = prerequisiteResults[0].result;
    const printer = printers.find(p => p._id === printerRef?._id) || printers.find(p => p.default);
    const labelSizes = prerequisiteResults[1].result;
    const size = labelSizes.find(s => s._id === sizeRef?._id) || labelSizes[0];

    let dataToPrint = [];
    let isSequentialPrint = foreignNamespace === 'print' && foreignRelation === 'job';
    if (isSequentialPrint) {
        dataToPrint = generateSequentialRecords(newRecord);
    } else {
        dataToPrint = await _p.getGridData(foreignNamespace, foreignRelation, routePath, selection);
    }

    if (!dataToPrint.length) {
        throw new Error('Failed to print: Unable to generate data to print.');
    }

    const zpl = await ZPL.generate(dataToPrint, template, size, printer);

    await _p.sendToPrinter(printer, zpl);
    return {
        ...data,
        result: {
            count: dataToPrint.length
        }
    };
}

function generateSequentialRecords(newRecord) {
    let { prefix = '', start = 1, end = 1, increment = 1, leadingZeroes, numDigits = 4 } = newRecord;
    if (!start || !end) return [];
    if (increment < 1) increment = 1;
    if (end < start) {
        const temp = end;
        end = start;
        start = temp;
    }
    let result = [];
    for (let index = start; index <= end; index += increment) {
        let value = index.toString();
        if (leadingZeroes) {
            value = value.padStart(numDigits, 0);
        }
        result.push({ _id: `seq${index}`, title: `${prefix}${value}` });
    }
    return result;
}
