import lodash from 'lodash';
const { omit } = lodash;
import { metadata, localStorage } from 'lib_ui-services';
const { set } = lodash;
import asTransactionCode from './asTransactionCode';
import getFormElementHNodes from './getFormElementHNodes';

const PREVIOUS_SELECTIONS_KEY = 'PreviousHNodeSelections';
const PRESELECTED_KEY = 'DefaultHNodeSelections';
const _p = {
    localStorage,
    getDictionary
};
export const _private = _p;
export default async function setDefaultValuesIfNecessary(context, newModel) {
    const { hNode, isNew, namespace, relation } = context;

    Object.entries(newModel).forEach(([key, value]) => {
        if (value === '') {
            delete newModel[key];
        }
    });

    if (isNew) {
        // If a model value is undefined, has a corresponding default value and is required
        // then set the model value to the default value.
        let hNodes;
        if (hNode != null) {
            hNodes = getFormElementHNodes(hNode.children);
        } else {
            const dictionary = await _p.getDictionary();
            const values = omit(dictionary[namespace][relation], '_meta');
            hNodes = Object.values(values).map(value => value._meta);
        }
        await Promise.all(
            hNodes
                .filter(h => (h.required || h.hNodeType === 'Toggle') && typeof h.defaultValue !== 'undefined')
                .map(async hNode => {
                    if (typeof newModel[hNode.propertyName] === 'undefined') {
                        newModel[hNode.propertyName] = hNode.defaultValue;
                    }
                })
        );

        await Promise.all(
            hNodes.map(async hNode => {
                const defaultToPreviousSelection = metadata.findHNode(
                    hNode.children ?? [],
                    'DefaultToPreviousSelection'
                );
                if (defaultToPreviousSelection) {
                    const { id } = defaultToPreviousSelection;
                    const previousSelections = await _p.localStorage.getKey(PREVIOUS_SELECTIONS_KEY + id);
                    if (previousSelections) {
                        const path = metadata.getPathToProperty(hNode);
                        set(newModel, path, previousSelections);
                        return;
                    }
                }

                const defaultValueFilter = metadata.findHNode(hNode.children ?? [], 'DefaultValue');
                if (defaultValueFilter) {
                    const { id } = defaultValueFilter;
                    const preSelection = await _p.localStorage.getKey(PRESELECTED_KEY + id);
                    if (preSelection) {
                        const { title, _id } = preSelection;
                        const path = metadata.getPathToProperty(hNode);
                        set(newModel, path, { title, _id });
                    }
                }
            })
        );

        if (!newModel.title && namespace === 'item' && relation === 'transaction') {
            // "transactions" by their very nature don't have a title.
            // But everything in backbone assumes one. So, duplicate the _id as title.
            newModel.title = asTransactionCode(newModel._id);
        }
    }
}

async function getDictionary() {
    // Should be populated by loadUseCase.js
    const dictionary = await _p.localStorage.getKey('dataDictionary');
    if (dictionary == null) {
        throw new Error('No data dictionary was found to set default values for the model.');
    }
    return dictionary;
}
