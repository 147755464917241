import { useState, useEffect } from 'react';
import { defaultSize } from '../constants';
import lodash from 'lodash';
const { isEqual } = lodash;
import useSelectedFormEntry from './useSelectedFormEntry';

const _p = {
    useSelectedFormEntry
};
export const _private = _p;

export default function useLabelSize() {
    const [labelSize, setLabelSize] = useState({ labelWidth: 200, labelHeight: 100 });
    const selectedSize = _p.useSelectedFormEntry({ namespace: 'print', relation: 'size' }, defaultSize);
    useEffect(() => {
        const labelWidth = (selectedSize.width || 2) * 100;
        const labelHeight = (selectedSize.height || 1) * 100;
        const newSize = { labelWidth, labelHeight };
        if (!isEqual(newSize, labelSize)) {
            setLabelSize(newSize);
        }
    }, [labelSize, selectedSize]);

    return labelSize;
}
