export default function testProperties(hNode, description, index) {
    if (hNode.testID != null) {
        return { 'data-testid': hNode.testID };
    }
    if (hNode['data-testid'] != null) {
        return { 'data-testid': hNode['data-testid'] };
    }
    const parts = [description, index, hNode.id];
    return { 'data-testid': parts.filter(p => p != null).join('-') };
}
