import { createElement as rc, useContext, useMemo } from 'react';
import Highcharts from 'highcharts';
import _HighchartsReact from 'highcharts-react-official';
import { ThemeContext } from 'styled-components';
import Chart from './Chart';
import { View, styled, hooks } from 'lib_ui-primitives';
const { useMemoizedObject } = hooks;
const HighchartsReact = _HighchartsReact.default || _HighchartsReact;
HighchartsReact.displayName = 'HighchartsReact';

const Stack = styled(View).attrs({ name: 'PieChart' })`
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-evenly;

    .highcharts-container,
    .highcharts-container svg {
        width: 100% !important;
        height: 100% !important;
    }
`;
const EMPTY_ARRAY = [];
function Pie(props) {
    const { data = EMPTY_ARRAY } = props || { hNode: {} };

    const theme = useContext(ThemeContext);
    const memoizedData = useMemoizedObject(data);
    const options = useMemo(() => {
        const { viewPaddingMore, disabledFontColor, defaultFontColor, chartColors } = theme;
        return {
            chart: {
                // use css stylesheets instead of inline styles to avoid CSP problems
                styledMode: true,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: undefined,
                spacing: [0, viewPaddingMore, 0, viewPaddingMore]
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b><br/>% of Total: <b>{point.percentage:.1f}%</b>'
            },
            colors: chartColors,
            size: '100%',
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true,
                    size: 250
                }
            },
            credits: {
                enabled: false
            },
            legend: {
                labelFormat: '{name} - {y}',
                itemHiddenStyle: { color: disabledFontColor },
                itemHoverStyle: { color: disabledFontColor },
                itemMarginTop: 5,
                itemStyle: { color: defaultFontColor },
                width: '100%'
            },
            series: [
                {
                    name: 'Count',
                    colorByPoint: true,
                    data: memoizedData.map(x => ({ name: x._id.title, y: x.count }))
                }
            ]
        };
    }, [memoizedData, theme]);

    return rc(
        Stack,
        null,
        rc(HighchartsReact, {
            highcharts: Highcharts,
            options,
            height: '100%',
            width: '100%'
        })
    );
}

function PieChart(props) {
    return Chart(Pie, props);
}
export default PieChart;
