import lodash from 'lodash';
const { get } = lodash;
import ZPL from './ZPL';
import JsBarcode from 'jsbarcode';
import { textWidth } from 'lib_ui-primitives';

const MARGIN = 5;

const TEXT_HEIGHT = 28; //font '0' at fontsize 10 is about 28 dots high
const CHARACTER_WIDTH = 28; //1em = ~28 dots
const BARCODE_WITHOUT_TEXT_HEIGHT = 35;

export async function generate(data, template, size, printer) {
    const { height, width } = size;
    const dpi = printer.dpi || 203;
    const labelWidth = width * dpi;
    let lineCount = 0;
    const dataHeight = template.reduce((total, content) => {
        switch (content.hNodeType) {
            case 'BarcodeContent': {
                lineCount += 2;
                return total + BARCODE_WITHOUT_TEXT_HEIGHT + TEXT_HEIGHT;
            }
            default: {
                lineCount++;
                return total + TEXT_HEIGHT;
            }
        }
    }, 2 * MARGIN);

    //calculate the spacing before the first line, plus after each line
    const spacing = Math.floor((height * dpi - dataHeight) / (1 + lineCount));
    const center = Math.round(labelWidth / 2);
    let zpl = [];
    for (const record of data) {
        let y = Math.floor(spacing / 2);
        let result = ZPL.createLabel(labelWidth, height * dpi).addComment(record._id);
        for (const content of template) {
            switch (content.hNodeType) {
                case 'BarcodeContent': {
                    const value = get(record, content.propertyName, '');
                    //barcode is generated with centered at the cursor
                    const halfBarcodeHeight = BARCODE_WITHOUT_TEXT_HEIGHT / 2;
                    y += halfBarcodeHeight + MARGIN;
                    if (value) {
                        //barcodes don't have an `alignment = "center"` option. Approximate:
                        const x = center - getBarcodeLength(value, labelWidth) / 2;
                        result = result.moveTo(x, y).addBarcode(value, { barcodeHeight: BARCODE_WITHOUT_TEXT_HEIGHT });
                    }
                    //add some spacing before the next field
                    y += halfBarcodeHeight + spacing;
                    if (shouldIncludeHumanReadable(content)) {
                        //now add the text underneath:
                        ({ y, result } = await PrintTextLabel(record, content, labelWidth, y, result, spacing));
                    }
                    break;
                }
                default: {
                    ({ y, result } = await PrintTextLabel(record, content, labelWidth, y, result, spacing));
                    break;
                }
            }
        }
        zpl.push(result.toString());
    }

    return zpl.join('\n\n');
}

export async function PrintTextLabel(record, content, labelWidth, y, result, spacing) {
    let value = get(record, content.propertyName, '').trim() || content.fixedText || '';

    const averageCharacterWidth = CHARACTER_WIDTH / 2;
    if (value.length * averageCharacterWidth > labelWidth) {
        //limit to first 2 lines only
        value = await trimToMax2Lines(value, labelWidth - 2 * MARGIN);
        //text is generated with the first line of text bottom at the cursor's y coordinate.
        // but we need to offset due to needing more space for the 2nd line due to wrapping
        y += Math.floor(TEXT_HEIGHT / 2) - MARGIN;
    } else {
        //text is generated with the bottom at the cursor's y coordinate. adjust for text height
        y += TEXT_HEIGHT - MARGIN;
    }

    result = result
        .moveTo(0, y)
        // font 0 at 28x28 roughly matches Arial fontsize 12 bold, scaled 1.2x vertically;
        .setFont(0, 'N', TEXT_HEIGHT, CHARACTER_WIDTH)
        .setAlignment('center')
        .addText(value);
    if (value.length * averageCharacterWidth > labelWidth) {
        //expect 2 lines due to wrapping
        y += Math.floor(TEXT_HEIGHT / 2) + spacing;
    } else {
        y += spacing;
    }
    return { y, result };
}

const UNIT_SEPARATOR = '\n';
async function trimToMax2Lines(value, labelWidth) {
    // split words on spaces and dashes, remove the spaces, but not the dashes
    // can't use a positive look behind with zero length assertion like /\s|(?<=-)/
    // As ReactNative does not support that yet
    const words = value
        .replaceAll(' ', UNIT_SEPARATOR)
        .replaceAll('-', '-' + UNIT_SEPARATOR)
        .split(UNIT_SEPARATOR);
    let lines = [];
    let currentLine = 0;
    for (const word of words) {
        const lineLength = await textWidth.measure(
            lines[currentLine] ? `${lines[currentLine]} ` : '',
            'Arial, sans-serif',
            CHARACTER_WIDTH
        );
        const wordLength = await textWidth.measure(word, 'Arial, sans-serif', CHARACTER_WIDTH);

        if (!lines[currentLine]) {
            lines[currentLine] = word;
        } else if (lineLength + wordLength <= labelWidth) {
            lines[currentLine] = `${lines[currentLine]} ${word}`;
        } else {
            if (currentLine === 0) {
                lines[currentLine + 1] = word;
                currentLine++;
            } else if (currentLine === 1) {
                lines[currentLine] = `${lines[currentLine]} ${word}`;
                while (
                    (await textWidth.measure(lines[currentLine], 'Arial, sans-serif', CHARACTER_WIDTH)) > labelWidth
                ) {
                    lines[currentLine] = lines[currentLine].substring(0, lines[currentLine].length - 1).trim();
                }
                break;
            } else {
                break;
            }
        }
    }
    //join what is left back together. remove any spaces we might be putting in behind a dash
    return lines.join(' ').replace(/-\s/g, '-');
}

export function getBarcodeLength(value, labelWidth, barWidth = 2) {
    if (!value) return 0;
    //Ideal Case, Subset C (numeric only, even length)
    const data = {};
    JsBarcode(data, value, { format: 'CODE128', height: 16, width: barWidth, fontSize: 10, textMargin: 1 });
    return data.encodings[0].data.length * barWidth;
    // console.log(data);
    // //worst case, Subset B:
    // //code 128 B uses 0.526" for the first 2 characters, and 0.108 for each character thereafter
    // return Math.round((0.526 + (value.length - 2) * 0.108) * dpi);
}

function shouldIncludeHumanReadable(content) {
    //we are having some issues with nested toggles. this will allow us to continue using either include or exclude
    // default: show the human readable under the barcode
    let humanReadableOnBarcode = true;
    if (typeof content.excludedHumanReadable === 'boolean') {
        // support typo in Asset Tracking configuration
        humanReadableOnBarcode = !content.excludedHumanReadable;
    } else if (typeof content.excludeHumanReadable === 'boolean') {
        humanReadableOnBarcode = !content.excludeHumanReadable;
    } else if (typeof content.includeHumanReadable === 'boolean') {
        humanReadableOnBarcode = content.includeHumanReadable;
    }
    return humanReadableOnBarcode;
}

export default { generate };
