import { useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { globalConfig } from 'lib_ui-services';

// DEVELOPER NOTE TO SAVE YOU FROM MY MISTAKES AND PAIN
/**
 * If you edit this, be aware that it is important not to make changes
 * that will cause the top level references (i.e. core, query, history,
 * location, setRouteState) to change as this will cause unnecessary
 * rerenders of the whole app (this is consumed by useOrchestrator via
 * useNavigation), and will also cause the route specific state
 * storage to be messed up.
 * If you are uncertain, you can use this to check:
 * import useWhatChanged from '../../utility/useWhatChanged';
 */

const loginPath = '/login';
const location = {};
export const _private = {
    getWindow: () => {
        return window;
    },
    useLocation: () => {
        return useOverrideUseLocation();
    },
    useHistory: () => {
        return useHistory();
    }
};

// Mutate a constant location to avoid rerenders.
function useOverrideUseLocation() {
    const _location = useLocation();
    Object.keys(_location).forEach(key => {
        location[key] = _location[key];
    });
    return location;
}

// Transient route specific state (intentionally destroyed if the user
// refreshes.)
const routeState = {};
const routeStateSearchParamKeys = [];

/**
 *
 * @returns {{
 *  protocol: 'http'|'https'|string,
 *  hostname :'app.sstid.com'|'localhost'|string,
 *  host : 'https://app.sstid.com'|'localhost:8888' |string,
 *  port : 445|888|string,
 *  brand: 'redbeam'|'sst'|string,
 *  environment:'local'|'prod'|'demo'|'staging'|'dev'
 *  groupNumber:'0'|'1'|string,
 *  query: queryString.ParsedQuery<string>,
 *  goToLocation: (path:string)=>void,
 *  replaceCurrentLocation: (path:string)=>void,
 *  redirectToLogin: (token?:string)=>void,
 *  isAtLogin: ()=>boolean,
 *  isDeployed: boolean
 *  ensureSslIfDeployed: ()=>void,
 *  ensureProperGroup: (user:object)=>void,
 *  history: import('history').History<unknown>,
 *  location: import('history').Location<unknown>,
 *  getRouteState: (stateName:string)=>any,
 *  setRouteState: (stateName:string, state:any)=>void,
 *  getRouteStateKey: ()=>string
 * }}
 */
export default function useRouter() {
    const location = _private.useLocation();
    //Do we need to worry about not having a history object?
    const history = _private.useHistory();
    // Return our custom router object
    // Memoize so that a new object is only returned if something changes

    const query = useMemo(() => queryString.parse(location.search), [location.search]);
    const groupNumber = getGroupNumberFromPath(location.pathname);
    const core = useMemo(() => {
        const windowLoc = _private.getWindow().location;
        const result = {
            brand: windowLoc.host.includes('redbeam') ? 'redbeam' : 'sst',
            environment: globalConfig().environment,
            groupNumber,
            host: windowLoc.host, //host plus port (if any)
            hostname: windowLoc.hostname,
            port: windowLoc.port,
            protocol: windowLoc.protocol
        };
        return result;
    }, [groupNumber]);

    const getRouteStateKey = useCallback(() => {
        const query = queryString.parse(location.search);
        return (
            location.pathname +
            routeStateSearchParamKeys.reduce((sofar, param) => {
                return `${sofar}${query?.[param] || ''}`;
            }, '')
        );
    }, [location]);
    const setRouteState = useCallback(
        (stateName, state) => {
            const key = getRouteStateKey();
            routeState[key] = routeState[key] || {};
            routeState[key][stateName] = state;
        },
        [getRouteStateKey]
    );

    return useMemo(() => {
        return {
            //"non changing" stuff
            ...core,
            query,
            goToLocation: history.push,
            replaceCurrentLocation: history.replace,
            redirectToLogin: token => {
                //Should we use history.replace here?
                let groupPrefix = core.groupNumber ? `/g/${core.groupNumber}` : '';
                //if we are silently re-logging in, stay with the group
                if (token) history.push(`${groupPrefix}${loginPath}?token=${token}`);
                else history.push(`${groupPrefix}${loginPath}`); //otherwise go to the login.
            },
            isAtLogin: () => location.pathname === loginPath,
            isDeployed: globalConfig().isDeployed,
            ensureSslIfDeployed: () => {
                if (globalConfig().isDeployed && _private.getWindow().location.protocol !== 'https:') {
                    _private.getWindow().location.href =
                        'https:' +
                        _private.getWindow().location.href.substring(_private.getWindow().location.protocol.length);
                }
            },
            ensureProperGroup: user => {
                if (!user || location.pathname.includes('login')) return;
                const groupNumber = user.group?.title[1];
                // Correct: No group and no group in path
                if (!groupNumber && !location.pathname.startsWith('/g/')) return;
                // Correct: Group and group in path
                if (groupNumber && location.pathname.startsWith(`/g/${groupNumber}/`)) return;

                let [, prefix, , ...restGrouplessPath] = location.pathname.split('/');
                const grouplessPath = prefix !== 'g' ? location.pathname : '/' + restGrouplessPath.join('/');

                const window = _private.getWindow();
                const server = `${window.location.protocol}//${window.location.host}`;
                if (!groupNumber) {
                    // No group, so revert to groupless path
                    window.location = `${server}${grouplessPath}`;
                    return;
                } else {
                    // Has group, so set to correct path with a group prefix
                    window.location = `${server}/g/${groupNumber}${grouplessPath}`;
                    return;
                }
            },
            redirectToGroupRoot: user => {
                if (user == null) {
                    throw new Error('A user must be present to redirect to the group root.');
                }
                const groupNumber = user.group?.title[1];
                if (groupNumber == null) {
                    throw new Error('A group is not present for the given user.');
                }

                _private.getWindow().location = `/g/${groupNumber}/`;
                return;
            },
            //include history just in case.
            history,
            location,
            /**
             * Retrieve transient application state for the current route.
             * State will be destroyed if the user refreshes.
             * @param {state} stateName - identifier for state to retrieve
             * @param {state} defaultValue - will lazily load the state if necessary
             * @returns the state
             */
            getRouteState: (stateName, defaultValue) => {
                const key = getRouteStateKey();
                routeState[key] = routeState[key] || {};
                routeState[key][stateName] = routeState[key][stateName] ?? defaultValue;
                return routeState[key][stateName];
            },
            registerRouteStateKey: key => {
                if (!routeStateSearchParamKeys.includes(key)) {
                    routeStateSearchParamKeys.push(key);
                }
            },
            /**
             * Store application state for the current route.  Allows having
             * different state under different navigation headers.
             * State will be destroyed if the user refreshes.
             * @param {string} stateName - identifier for this state
             * @param {any} state - any state that can be stored in an object field
             */
            setRouteState,
            getRouteStateKey,
            resetRouteState: () => (routeState[`${location.pathname}${location.search || ''}`] = {})
        };
    }, [core, query, history, location, setRouteState, getRouteStateKey]);
}
useRouter._private = _private;

function getGroupNumberFromPath(path) {
    if (path.startsWith('/g/')) {
        const [, , groupNumber] = path.split('/');
        return groupNumber;
    }
    return '';
}
