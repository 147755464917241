import { hooks } from 'lib_ui-primitives';

const { useRouter } = hooks;

/**
 * @returns {'sst'|'redbeam'}
 */
export default function useBrand() {
    const { hostname } = useRouter();

    if (/redbeam/i.test(hostname)) return 'redbeam';

    return 'sst';
}
