import { database } from 'lib_ui-services';
export default {
    verb: 'doingReset',
    namespace: 'application',
    relation: 'notification',
    description: 'Reset (i.e. remove) all notifications',
    //this is the actual logic:
    logic: doingReset
};

async function doingReset({ context }) {
    const currentTime = new Date().toISOString();
    const currentUser = { _id: context.user._id, displayName: context.user.displayName };
    const db = database.get();
    await db.updateMany(
        {
            criteria: { 'meta.deleted': { $exists: false } },
            operations: { $set: { 'meta.deleted': true, deletedBy: currentUser, deletedTime: currentTime } }
        },
        context
    );
}
