import { Link, Text, View, fromTheme, webOnlyStyles, Pressable, styled } from 'lib_ui-primitives';
import Collapse from '../helpers/Collapse';
import ArrowIcon from '../_abstractComponent/DropDown/ArrowIcon';

export const NoShrink = styled(View)`
    flex-shrink: 0;
    flex-direction: column;
`;

export let Noncollapsible = styled(View).attrs({ name: 'noncollapsible' })`
    background-color: ${({ isActive, theme }) => {
        if (isActive) {
            return theme.colorScheme['background-dark'];
        }
        return 'transparent';
    }};
`;
Noncollapsible.displayName = 'Noncollapsible';
Noncollapsible = webOnlyStyles(Noncollapsible)`
    &:hover {
        background-color: ${fromTheme('colorScheme', 'background-dark')};
    }
`;

export let NavHeaderPlain = styled(Pressable).attrs({ name: 'nav-header-plain' })`
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: flex-start;
    letter-spacing: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: 'rgba(0,0,0,0)';
    border-right-color: 'rgba(0,0,0,0)';
`;
NavHeaderPlain = webOnlyStyles(NavHeaderPlain)`
    cursor: pointer;
    &:hover {
        background-color: ${fromTheme('colorScheme', 'background-dark')};
        border-right-color: ${fromTheme('colorScheme', 'background-dark')};
    }
`;

export let DisabledNavHeader = styled(View).attrs({ name: 'DisabledNavHeader' })`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    justify-content: flex-start;
    letter-spacing: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    flex-grow: 0;
    background-color: 'rgba(0,0,0,0)';
    border-right-color: 'rgba(0,0,0,0)';
`;
DisabledNavHeader = webOnlyStyles(DisabledNavHeader)`
    cursor: no-drop;
`;
DisabledNavHeader.displayName = 'DisabledNavHeader';

export let NavHeaderLink = styled(Link).attrs({ name: 'nav-header-link' })`
    display: flex;
    flex-shrink: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    letter-spacing: 0;
    flex-grow: 1;
    background-color: 'rgba(0,0,0,0)';
    border-right-color: 'rgba(0,0,0,0)';
    text-decoration-line: none;
    border-bottom-color: #00000000;
`;
NavHeaderLink = webOnlyStyles(NavHeaderLink)`
    &:hover {
        background-color: ${fromTheme('colorScheme', 'background-dark')};
        border-right-color: ${fromTheme('colorScheme', 'background-dark')};
    }
`;

export const ActiveNavHeaderLink = styled(NavHeaderLink).attrs({ name: 'active-nav-header-link' })`
    background-color: ${fromTheme('colorScheme', 'background-dark')};
    border-right-color: ${fromTheme('colorScheme', 'background-dark')};
`;

export const LinkText = styled(Text)`
    font-family: ${fromTheme('fontAlternate')};
    color: ${fromTheme('colorScheme', 'white-text')};
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
`;

export let DisabledLinkText = styled(LinkText).attrs({ name: 'DisabledLinkText' })`
    color: ${fromTheme('outerMenus', 'disabledFontColor')};
`;
DisabledLinkText.displayName = 'DisabledLinkText';

export const CollapseOffset = styled(View).attrs({ name: 'collapse-offset' })`
    margin-left: 24px;
`;

export const FullWidthCollapse = styled(Collapse).attrs({ name: 'full-width-collapse' })`
    flex-grow: 1;
    flex-direction: column;
`;

// Use ArrowIcon SVG here instead of a material icon to avoid problem where
// the initial load displays before material icons are downloaded (which
// resulted in this briefly displaying text instead of the icon).
export const CollapseIndicator = styled(ArrowIcon).attrs({ name: 'collapse-indicator' })`
    margin-right: 6px;
    width: 8px;
    height: 8px;
`;
CollapseIndicator.displayName = 'CollapseIndicator';

export const CollapseSpacer = styled(View).attrs({ name: 'collapse-spacer' })`
    flex-shrink: 0;
    margin-right: 6px;
    width: 8px;
    height: 8px;
`;
CollapseSpacer.displayName = 'CollapseSpacer';
