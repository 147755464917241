import { useState, useEffect } from 'react';
import useMultiSelect from '../../../../hooks/useMultiSelect';
import useFormContext from '../../../../hooks/useFormContext';
import useDbView from '../../../../hooks/useDbView';
import lodash from 'lodash';
import prettyPrintNumber from './prettyPrintNumber';
const { isEqual } = lodash;

const _p = {
    useDbView,
    useMultiSelect,
    useFormContext,
    MAX_PREVIEW: 100,
    MAX_SEQUENTIAL: 10000
};
export const _private = _p;

/**
 * Data comes either from a grid (using select boxes)
 * OR from specs for a sequence.
 * Otherwise, use a mocked sample record.
 */
export default function usePreviewData(props, template) {
    const {
        hNode,
        hNode: { namespace, relation }
    } = props || { hNode: {} };
    const { newRecord = {} } = _p.useFormContext();

    const inTemplateDesignMode = namespace === 'print' && relation === 'template';
    const inSequentialMode = namespace === 'print' && relation !== 'template';

    const [dataToPrint, setDataToPrint] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [errors, setErrors] = useState([]);

    const { isSelected, selection } = _p.useMultiSelect({ namespace, relation });
    const { records } = _p.useDbView(
        namespace,
        relation,
        undefined,
        hNode,
        !inTemplateDesignMode && !inSequentialMode,
        false
    );
    useEffect(() => {
        if (inTemplateDesignMode || inSequentialMode) return;

        const selectedRecords = records.filter(x => isSelected(x._id));
        if (isEqual(dataToPrint, selectedRecords)) {
            if (selectedRecords.length === 0 && !errors.length) {
                setErrors(['Please select at least 1 record to print ']);
            }
            return;
        }
        setTotalRecords(selectedRecords.length);
        if (selectedRecords.length === 0) {
            setErrors(['Please select at least 1 record to print ']);
        } else if (selectedRecords.length > _p.MAX_PREVIEW) {
            setErrors([
                `Display limited to first ${_p.MAX_PREVIEW} Records. All ${selectedRecords.length} records will be printed.`
            ]);
        } else {
            setErrors([]);
        }
        setDataToPrint(selectedRecords.slice(0, _p.MAX_PREVIEW));
    }, [records, isSelected, inTemplateDesignMode, inSequentialMode, dataToPrint, errors.length]);

    useEffect(() => {
        if (inTemplateDesignMode) {
            const records = getDesignData(template);
            if (isEqual(dataToPrint, records)) return;
            setTotalRecords(records.length);
            setDataToPrint(records);
        }
        if (inSequentialMode) {
            const data = generateSequentialRecords(newRecord, _p.MAX_SEQUENTIAL);
            if (isEqual(dataToPrint, data)) return;
            setTotalRecords(data.length);
            if (data.length === 0) {
                setErrors(['Please select at least 1 record to print ']);
            } else if (data.length >= _p.MAX_SEQUENTIAL) {
                setErrors([
                    `Unable to generate more than ${prettyPrintNumber(_p.MAX_SEQUENTIAL)} records at a time.`,
                    `Display limited to first ${_p.MAX_PREVIEW} Records.`,
                    `Only the first ${prettyPrintNumber(_p.MAX_SEQUENTIAL)} records will be printed.`
                ]);
            } else if (data.length > _p.MAX_PREVIEW) {
                setErrors([
                    `Display limited to first ${_p.MAX_PREVIEW} Records. All ${data.length} records will be printed.`
                ]);
            } else {
                setErrors([]);
            }
            setDataToPrint(data.slice(0, _p.MAX_PREVIEW));
        }
    }, [newRecord, template, inTemplateDesignMode, inSequentialMode, dataToPrint]);

    return { dataToPrint, totalRecords, errors, selection };
}

const ipsumRecord = {
    title: '000001',
    description: 'Sample Description',
    extra1: 'Sample Description',
    extra2: 'Sample Description',
    extra3: 'Sample Description',
    extra4: 'Sample Description',
    extra5: 'Sample Description'
};
function getDesignData(instantiatedTemplate) {
    const record =
        instantiatedTemplate.reduce((result, line) => {
            if (!line.propertyName) {
                return result;
            } else if (line.sampleData === '') {
                return { ...result, [line.propertyName]: ' ' };
            } else if (line.sampleData != null) {
                return { ...result, [line.propertyName]: line.sampleData };
            } else {
                return { ...result, [line.propertyName]: ipsumRecord[line.propertyName] || 'Sample Description' };
            }
        }, ipsumRecord) || ipsumRecord;
    return [record];
}

function generateSequentialRecords(formModel, max) {
    let { prefix = '', start = 1, end = 1, increment = 1, leadingZeroes, numDigits = 4 } = formModel;
    if (!start || !end) return [];
    if (increment < 1) increment = 1;
    if (end < start) {
        end = start;
    }
    if (end - start >= max) {
        // start and end both will be printed, so only add 1 less
        end = start + (max - 1);
    }
    let result = [];
    for (let index = start; index <= end; index += increment) {
        let value = index.toString();
        if (leadingZeroes) {
            value = value.padStart(numDigits, 0);
        }
        result.push({ _id: `seq${index}`, title: `${prefix}${value}` });
    }
    return result;
}
