import { createElement as rc } from 'react';
import PropTypes from 'prop-types';

import { DatePicker as _DatePicker } from 'lib_ui-primitives';
import FormField from './FormField';
import useFormControl from '../../hooks/useFormControl';

/**
 * @typedef {Object} Props
 * @property {import('../../../types').HNodeFormElementDatePicker} hNode
 */
/** @type {import('react').FC<Props>} */
const DatePicker = props => {
    const {
        hNode: { format, id, placeholder, tooltip, readOnly, showAsModal, treePosition }
    } = props || { hNode: {} };

    const { title, value, setValue, disabled, errors, active, onFocus, onBlur } = useFormControl(props);
    const fieldEmpty = [undefined, null, ''].includes(value);

    // prettier-ignore
    return rc(FormField, { id, title, tooltip, errors, active, fieldEmpty },
        rc(_DatePicker, {
            format,
            id,
            onBlur,
            onChange: setValue,
            onFocus,
            placeholder,
            disabled: disabled || readOnly,
            showAsModal,
            title,
            value,
            name: title,
            sequence: treePosition.sequence
        })
    );
};

DatePicker.defaultProps = {};

DatePicker.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};

export default DatePicker;
