import react from 'react';
import useEventSink from './useEventSink';
import useUserContext from './useUserContext';
import { orchestrator } from 'lib_ui-core';
import { globalConfig } from '../../../lib_ui-services/src';
import useNavigation from './useNavigation';
const { useState, useEffect } = react;
export const STATUS = {
    notStarted: 'not started',
    pending: 'pending',
    success: 'success',
    error: 'error'
};

export default function useOrchestrator() {
    const [status, setStatus] = useState(STATUS.notStarted);
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);

    const eventSink = useEventSink();
    const [subscribe] = eventSink;

    const user = useUserContext();
    globalConfig().setGroupNumber(user?.group?.title[1] ?? 0);
    globalConfig().setFeatureFlags(user?.allFeatureFlags ?? []);
    useNavigation();

    useEffect(() => {
        let allowStateUpdate = true;
        setStatus(STATUS.pending);
        setValue(null);
        setError(null);
        if (user && !user.needTenantSelection && user.activeUseCase !== undefined) {
            executeAsyncStuff();
        }

        // Subscribe to the Caption Overrides event
        // This is needed to reload the use case metadata when the Caption Overrides are updated
        const captionOverridesEventContext = {
            verb: 'upsert',
            namespace: 'metaui',
            relation: 'captionOverrides'
        };
        const unsubscribes = [
            subscribe(captionOverridesEventContext, payload => {
                if (payload?.newRecord !== null) {
                    // If Caption Overrides have been updated, then we need to reload the use case metadata, but we don't need to synchronize data
                    executeAsyncStuff(true);
                }
            })
        ];

        /**
         * Setup the use case and synchronize data if needed
         * @param {boolean} skipSync - if true, will not synchronize data
         * @returns {Promise<void>}
         */
        async function executeAsyncStuff(skipSync) {
            try {
                const result = await orchestrator.orchestrateUseCase({ user, eventSink });
                setValue(result.useCase);
                // eslint-disable-next-line no-undef
                if (!__DISABLE_SYNC__ && !skipSync) {
                    const purgedRelations = await result.synchronizeData();
                    if (purgedRelations.length && allowStateUpdate) {
                        //We need the UI to reload to make sure any purged relations are re-connected
                        //set it to null, then back to the usecase to reload the UI.
                        setValue(null);
                        setValue(result.useCase);
                    }
                }
                if (allowStateUpdate) {
                    setStatus(STATUS.success);
                }
            } catch (error) {
                if (allowStateUpdate) {
                    setError(error);
                    setStatus(STATUS.error);
                }
            }
        }

        return () => {
            allowStateUpdate = false;
            // unsubscribe from the captionOverrides event
            return () => unsubscribes.map(u => u());
        };
        // we don't want to include `initialLocationNotification`
        // as we don't want to rerun this every time the route changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, eventSink]);

    return { status, error, useCase: value };
}
