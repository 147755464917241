let root = '/';
let group;
const hasGroup = /\/g\/(\d+).*/.test(self.location.pathname);
if (hasGroup) {
    const result = self.location.pathname.match(/\/g\/(\d+).*/);
    group = result[1];
    root = `/g/${group}/`;
}
let serviceWorkerPath = 'service-worker_v2.js';
if (group != null) {
    serviceWorkerPath = `/g/${group}/${serviceWorkerPath}`;
}
// eslint-disable-next-line no-undef
export const VERSION = __PACKAGE_VERSION__;
export const VERSION_KEY = `versions_v2_g${group}`;
export const DB_NAME = `sw_installation_v2_g${group}`;
// Fallback assets to cache
export const FALLBACK_HTML_URL = `${root}index.html`;
export const FALLBACK_CSS_URL = `${root}index.css`;
export const ROOT = root;
export const GROUP = group;
export const SERVICE_WORKER_PATH = serviceWorkerPath;
