import { createElement as rc, useState, useRef, useCallback, useMemo } from 'react';
import View from '../View';
import PortalContext from '../../contexts/PortalContext';
import GestureRecognizer from './GestureRecognizer';
import fromTheme from '../../fromTheme';
import webOnlyStyles from '../../webOnlyStyles';
import zindexes from '../../zindexes';
import nativeOnlyStyles from '../../nativeOnlyStyles';
import styled from '../../styled';
import Pressable from '../../components/Pressable';
import ReadContext from '../../contexts/ReadContext';
import useTimeout from '../../hooks/useTimeout';

const NonPortalFullScreenContainer = styled(View).attrs({ name: 'non-portal-container' })`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;
NonPortalFullScreenContainer.displayName = 'NonPortalContainer';

const NonPortalModalContainer = styled(View).attrs({ name: 'non-portal-modal-container' })`
    justify-content: center;
`;
NonPortalModalContainer.displayName = 'NonPortalModalContainer';

const PortalBackground = styled(Pressable).attrs({ name: 'portal-background' })`
    position: absolute;
    background-color: ${fromTheme('backgroundModal')};
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: ${zindexes.PortalBackground};
`;
PortalBackground.displayName = 'PortalBackground';

let PortalView = styled(View).attrs({ name: 'portal-view' })`
    padding: ${fromTheme('viewPadding')};
    margin: ${fromTheme('viewMarginMore')};
    background-color: ${fromTheme('baseBackgroundColorDarker')};
`;
PortalView = webOnlyStyles(PortalView)`
    max-height: ${({ theme }) => {
        return theme.height - 2 * theme.viewMarginMore;
    }}px;
    max-width: ${({ theme }) => {
        return theme.width - 2 * theme.viewMarginMore;
    }}px;
    boxShadow: 0 2px 2px 0 rgb(${fromTheme('boxShadowColor')} / 14%), 0 3px 1px -2px rgb(${fromTheme(
    'boxShadowColor'
)} / 20%), 0 1px 5px 0 rgb(${fromTheme('boxShadowColor')} / 12%);
`;
PortalView = nativeOnlyStyles(PortalView)`
    elevation: 10;
    width: ${({ theme }) => {
        return theme.width - 2 * theme.viewMarginMore;
    }}px;
`;
PortalView.displayName = 'PortalView';

/*
 * Here any `Portal` elements under `PortalHost` are rendered alongside `PortalHost`
 * children and will appear above those children like a `Modal`.  This component is
 * needed because React Native doesn't support traditional react portals.
 *
 * This could be improved by allowing the option of removing the background if
 * multiple simultaneously accessible portals are needed.  However, to do that
 * will require more layout code (and requirements) to position the portals in
 * some logical manner - which is beyond the scope of the current workitem.
 */
export default function PortalHost(props) {
    const { fullScreen, children } = props;
    const [portals, setPortals] = useState([]);
    const nextKey = useRef(0);

    const mount = useCallback((children, id, onUnmount, contexts) => {
        const key = nextKey.current++;

        setPortals(previous => [...previous, { key, children, id, onUnmount, contexts }]);

        return key;
    }, []);

    const update = useCallback((key, children) => {
        setPortals(previous => {
            return previous.map(item => {
                if (item.key === key) {
                    return { ...item, children };
                }
                return item;
            });
        });
    }, []);

    // Move to the end of stack to avoid change to state in other components while changing the state in this one.
    // Otherwise, you get "'Cannot update a component (`%s`) while rendering a different component (`%s`)."
    const portalUnmount = useTimeout(portal => portal?.onUnmount?.(), [], 0);
    const unmount = useCallback(
        key => {
            setPortals(previous => {
                const portal = previous.find(item => item.key === key);
                portalUnmount(portal);
                return previous.filter(item => item.key !== key);
            });
        },
        [portalUnmount]
    );

    const value = useMemo(
        () => ({
            mount,
            update,
            unmount
        }),
        [mount, update, unmount]
    );

    // prettier-ignore
    return rc(PortalContext.Provider, { value },
        /* Need collapsable=false here to clip the elevations, otherwise they appear above Portal components */
        fullScreen && rc(NonPortalFullScreenContainer, { collapsable: false, pointerEvents: 'box-none' }, children),
        !fullScreen && rc(NonPortalModalContainer, { collapsable: false, pointerEvents: 'box-none' }, children),
        // Add portals
        portals.map(({ key, children, id, contexts }) => {
            return rc(GestureRecognizer, {key},
                // There's probably a clever way to add all contexts dynamically, but I don't
                // have time to mess with it right now.
                rc(ReadContext.Provider, {value: contexts.ReadContext},
                    rc(PortalBackground, {onClick: ()=>unmount(key), key, id: `portalBackground-${id}`},
                        /* Need collapsable=false here to clip the elevations, otherwise they appear above sibling components */
                        rc(PortalView, { id: `${id}-${key}`, key, collapsable: false, pointerEvents: 'box-none' }, children)
                    )
                )
            );
        })
    );
}

PortalHost.displayName = 'PortalHost';
