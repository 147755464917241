import { constants } from 'lib_ui-services';

export default {
    verb: 'willChange',
    namespace: 'sensor',
    relation: 'tag',
    description: 'Auto populate number of characters field',
    logic: willChange
};

const _p = {
    wasScanned
};

export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willChange({ data, context: { user } }) {
    const displayInAscii = user.allFeatureFlags.includes('displayInAscii');
    if (data.propertyName !== 'value') {
        //if it is not a change to one of the fields that affect a calculated value
        return { ...data, newRecord: { ...data.newRecord, receivedAsScan: false } };
    }
    const receivedAsScan = _p.wasScanned(data);
    //RFID tags have a max size of 496 bit. 1 hex character = 4 bit.
    //So, max 124 hex character.
    if (data.newValue.length > 124) {
        data.newValue = data.newValue.substring(0, 124);
    }
    //display the data length based on displayInAscii
    const numChars = displayInAscii ? data.newValue.length / 2 : data.newValue.length;

    return { ...data, newRecord: { ...data.newRecord, numChars, receivedAsScan } };
}

function wasScanned(data) {
    return [constants.sensorTypes.BARCODE, constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(
        data.inputSource
    );
}
