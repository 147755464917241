import { createElement as rc, useRef, useEffect, useState } from 'react';
import { Card, Modal, webOnlyStyles, h2, h3, Button, styled, View, fromTheme, getQueue, Icon } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';

const ButtonBar = styled(View)`
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 1;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 32px;
    margin: 6px;
    width: 100%;
`;

const ConfirmationCard = webOnlyStyles(Card)`
    box-shadow: unset;
`;

const HeaderText = styled(h2)`
    color: ${fromTheme('card', 'header', 'textColor')};
`;

const Body = styled(Card.Body)`
    flex-direction: row;
    align-items: center;
`;

let DialogIcon = styled(Icon)`
    margin-left: 12px;
    margin-right: 24px;
    font-size: 42px;
`;
DialogIcon = webOnlyStyles(DialogIcon)`
    max-width: unset;
`;

const TopToBottom = styled(View)`
    flex-direction: column;
`;

let BigText = styled(h3)``;
BigText = webOnlyStyles(BigText)`
    margin-block-start: 0;
    margin-block-end: 0;
`;

const defaultDialogInfo = {
    message: '',
    okButtonText: 'OK',
    cancelButtonText: 'CANCEL',
    okAction: () => {},
    cancelAction: () => {},
    noCancelOption: false,
    jumpQueue: false
};

function ConfirmationDialog() {
    const [subscribe] = useEventSink();
    const [dialogInfo, setDialogInfo] = useState(defaultDialogInfo);
    const [visible, setVisible] = useState(false);
    // If more than one confirmation is requested, then queue them.
    // Note: this is a separate queue from the modal queue. This one only controls the content displayed INSIDE the confirmation dialog
    const queue = useRef(getQueue('ConfirmationDialog')).current;

    // When a new confirmation reaches the front of the queue, display it.
    useEffect(() => {
        queue.onNewLeader(newLeader => {
            if (newLeader != null) {
                setDialogInfo(newLeader);
                setVisible(true);
            }
        });
    }, [queue]);

    // Listen for confirmation dialog requests and queue them
    useEffect(() => {
        let openActionType = { verb: 'confirm', namespace: 'application', relation: 'user' };
        const unsubscribes = [
            subscribe(openActionType, payload => {
                const dialogInfo = { ...defaultDialogInfo, ...payload };
                if (dialogInfo.jumpQueue) {
                    queue.jumpQueue(dialogInfo);
                } else {
                    queue.enqueue(dialogInfo);
                }
            })
        ];
        // unsubscribe during cleanup
        return () => unsubscribes.forEach(u => u());
    }, [subscribe, queue]);

    const {
        title: defaultTitle,
        message,
        okButtonText,
        cancelButtonText,
        okAction,
        cancelAction,
        noCancelOption,
        icon,
        iconFont,
        iconVariation,
        iconColor,
        jumpQueue = false
    } = dialogInfo;

    const title = defaultTitle ?? (noCancelOption ? 'Please Be Advised' : 'Please Confirm');

    function onCancel() {
        setVisible(false);
        setDialogInfo(defaultDialogInfo);
        cancelAction();
        queue.next();
    }
    function onOk() {
        setVisible(false);
        setDialogInfo(defaultDialogInfo);
        okAction();
        queue.next();
    }

    let messageAsArray = [].concat(message);
    let mlmHtml = messageAsArray.map((line, i) => rc(BigText, { key: i }, line));

    // prettier-ignore
    return rc(Modal, { id: 'confirmationDialogModal', visible, jumpQueue },
        rc(ConfirmationCard, null,
            rc(Card.Header, null,
                rc(HeaderText, null, title)
            ),
            rc(Body, null,
                icon && rc(DialogIcon,
                    {
                        iconFont,
                        iconVariation,
                        fontColor: iconColor,
                        key: '0',
                        title: icon,
                        iconSize: '48px'
                    },
                    icon
                ),
                rc(TopToBottom, null, mlmHtml)
            ),
            rc(Card.Footer, null, rc(ButtonBar, null,
                !noCancelOption && rc(Button, { value: cancelButtonText, onClick: onCancel }),
                rc(Button, { value: okButtonText, onClick: onOk })
            ))
        )
    );
}

export default ConfirmationDialog;
