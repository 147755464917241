import { useEffect, createElement as rc } from 'react';
import { Text, ValidationError, ExternalLink, hooks } from 'lib_ui-primitives';
import { BarcodeContent, FixedTextContent, ReferencedContent } from './labelContent';
import useEventSink from '../../../hooks/useEventSink';
import useLabelSize from './hooks/useLabelSize';
import usePreviewData from './hooks/usePreviewData';
import useLabelTemplate from './hooks/useLabelTemplate';
import useCustomFormUpdater from './hooks/useCustomFormUpdater';
import { Label, PreviewArea, PreviewContainer, FixedContainer } from './styles';

const { useRouter } = hooks;

const labelContentTypes = { BarcodeContent, FixedTextContent, ReferencedContent };

const _p = {
    useRouter,
    useLabelSize,
    useLabelTemplate,
    usePreviewData,
    useCustomFormUpdater
};
export const _private = _p;

export default function PrintPreview(props) {
    const {
        hNode: { namespace, relation }
    } = props || { hNode: {} };

    const [, publish] = useEventSink();
    const router = _p.useRouter();

    const labelProps = _p.useLabelSize();
    const { template, inTemplateDesignMode } = _p.useLabelTemplate(props);
    const { dataToPrint, totalRecords, errors, selection } = _p.usePreviewData(props, template);

    _p.useCustomFormUpdater({
        template,
        foreignNamespace: namespace,
        foreignRelation: relation,
        selection,
        routePath: router.location.pathname
    });

    //only call the first time the preview is loaded so as to trigger populating the printer list
    useEffect(() => {
        publish({}, { verb: 'get', namespace: 'print', relation: 'printer', type: 'find' });
    }, [publish]);

    // prettier-ignore
    return rc(PreviewContainer, null,
        rc(PreviewArea,
            { id: props.hNode.id, name: 'Print-Preview' },
            dataToPrint.map(record =>
                rc(Label,
                    { key: `${record._id}PrintPreview`, ...labelProps },
                    template.map((hNode, i) => {
                        if (!labelContentTypes[hNode.hNodeType]) return null;
                        return rc(labelContentTypes[hNode.hNodeType],
                            {
                                key: `${record._id}-${i}PrintPreview`,
                                record,
                                label:labelProps,
                                hNode
                            }
                        );
                    }
                    )
                )
            )
        ),
        !inTemplateDesignMode && rc(FixedContainer, null, rc(Text, null, `Total ${totalRecords || 0} label${totalRecords === 1 ? '' : 's'}`)),
        rc(FixedContainer,{},rc(ValidationError, { errors })),
        rc(ExternalLink, { url: 'https://support.redbeam.com/knowledge/setting-up-your-printer', title: 'Printer Setup Instructions' })
    );
}
