//for more information see also https://www.zebra.com/content/dam/zebra/manuals/printers/common/programming/zpl-zbi2-pm-en.pdf

const LABEL_WIDTH = '^PW';
const LABEL_HEIGHT = '^LL';
/**
 * The ^BC command creates the Code 128 bar code, a high-density, variable length, continuous, alphanumeric symbology.
 * It was designed for complexly encoded product identification.
 * Code 128 has three subsets of characters.
 * There are 106 encoded printing characters in each set,
 * and each character can have up to three different meanings, depending on the character subset being used.
 * Each Code 128 character consists of six elements: three bars and three spaces.
 */
const CODE_128_BAR_CODE = '^BC';

/**
 * The ^BY command is used to change the default values for the module width (in dots),
 * the wide bar to narrow bar width ratio and the bar code height (in dots).
 * It can be used as often as necessary within a label format.
 */
const BAR_CODE_FIELD_DEFAULT = '^BY';

/**
 * The ^FD command defines the data string for a field.
 * The field data can be any printable character except those used as command prefixes (^ and ~).
 */
const FIELD_DATA = '^FD';

/**
 * The ^FO command sets a field origin, relative to the label home (^LH) position.
 * ^FO sets the upper-left corner of the field area by defining points along the x-axis and y-axis independent of the rotation.
 */
const FIELD_ORIGIN = '^FO';

/**
 * The ^FB command allows you to print text into a defined block type format.
 * This command formats an ^FD or ^SN string into a block of text using the origin, font and rotation specified for the text string.
 * the ^FB command also contains an automatic word-wrap function
 */
const FIELD_BLOCK = '^FB';

/**
 * The ^FR command allows a field to appear as white over black or black over white.
 * When printing a field and the ^FR command has been used, the color of the output is the reverse of its background.
 */
// eslint-disable-next-line no-unused-vars
const FIELD_REVERSE_PRINT = '^FR';

/**
 * The ^FS command denotes the end of the field definition.
 */
const FIELD_END = '^FS';

/**
 * The  ~SD  command  allows  you  to  set  the  darkness  of  printing.
 * Format: ~SD##
 * `##` Desired darkness setting (two-digit number 00 to 30)
 */
const SET_DARKNESS = '~SD';

/**
 * The ^PR command determines the media and slew speed (feeding a blank label) during printing.
 */
const SET_PRINT_SPEED = '^PR';

/**
 * The ^FX command is useful when you want to add non-printing informational comments or statements within a label format.
 * Any data after the ^FX command up to the next caret (^) or tilde (~) command does not have any effect on the label format.
 * Therefore, you should avoid using the caret (^) or tilde (~) commands within the ^FX statement.
 */
const COMMENT = '^FX';

/**
 * The ^XA command is used at the beginning of ZPL code.
 * It is the opening bracket and indicates the start of a new label format.
 */
const START_LABEL = '^XA';

/**
 * The ^XZ command is the ending (closing) bracket.
 * It indicates the end of a label format.
 * When this command is received, a label prints.
 */
const END_LABEL = '^XZ';

function createLabel(width, height) {
    let zpl = { value: '', width, height };
    zpl.value +=
        `${SET_DARKNESS}30\n` + //max darkness
        START_LABEL +
        `\n${SET_PRINT_SPEED}2,2,2\n` + //slowest speed
        `${LABEL_WIDTH}${width}` + //Sets the label print width
        `${LABEL_HEIGHT}${height}`; //Sets the label length
    return {
        moveTo: _moveTo(zpl),
        addComment: _addComment(zpl, false),
        toString: toStringNotAllowed
    };
}

function _addComment(zplSoFar, allowToString) {
    return function addComment(comment) {
        zplSoFar.value = zplSoFar.value + `\n${COMMENT}${comment}`;
        return {
            moveTo: _moveTo(zplSoFar),
            toString: allowToString ? _endLabelAndSerialize(zplSoFar) : toStringNotAllowed
        };
    };
}

function _moveTo(zplSoFar) {
    /**
     * Move relative to top left corner
     * @param {number} x left to right coordinate in dots
     * @param {number} y top to bottom coordinate in dots
     */
    return function moveTo(x, y) {
        if (x > zplSoFar.width) {
            throw new Error(`Invalid X coordinate. ${x},${y} is outside the label's ${zplSoFar.width}px width`);
        }
        if (y > zplSoFar.y) {
            throw new Error(`Invalid Y coordinate. ${x},${y} is outside the label's ${zplSoFar.height}px height`);
        }
        zplSoFar.value = zplSoFar.value + '\n' + `${FIELD_ORIGIN}${x},${y}`;
        return {
            setFont: _setFont(zplSoFar),
            setAlignment: _setAlignment(zplSoFar),
            addText: _addText(zplSoFar),
            addBarcode: _addBarcode(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _setFont(zplSoFar) {
    /**
     *
     * @param {*} font '0' prints about everything
     * @param {*} orientation default 'N' for Normal
     * @param {*} height font '0' at fontsize 10 is about 28 dots high
     * @param {*} width fonts '0' looks good when square, e.g. 28x28
     * @returns
     */
    return function setFont(font = '0', orientation = 'N', height, width) {
        zplSoFar.value = zplSoFar.value + `\n    ^A${font}`;
        if (orientation !== 'N') {
            zplSoFar.value += orientation; //no comma separator before orientation
        }
        if (height) {
            zplSoFar.value += ',' + height;
            if (width) {
                //if excluded, the font will auto scale
                zplSoFar.value += ',' + width;
            }
        }
        return {
            setAlignment: _setAlignment(zplSoFar),
            addText: _addText(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _setAlignment(zplSoFar) {
    return function setAlignment(alignment) {
        switch (alignment.toLowerCase()) {
            case 'center':
                zplSoFar.value = zplSoFar.value + `\n    ${FIELD_BLOCK}${zplSoFar.width},2,0,C`;
                break;
            case 'right':
                zplSoFar.value = zplSoFar.value + `\n    ${FIELD_BLOCK}${zplSoFar.width},2,0,R`;
                break;
            case 'justified':
                zplSoFar.value = zplSoFar.value + `\n    ${FIELD_BLOCK}${zplSoFar.width},2,0,J`;
                break;
            case 'left':
            default:
                //do noting
                break;
        }

        return {
            addText: _addText(zplSoFar),
            toString: toStringNotAllowed
        };
    };
}

function _addBarcode(zplSoFar) {
    return function addBarcode(value, specs = {}) {
        let specsWithDefaults = { type: 'CODE128', barWidth: 2, barcodeHeight: 41, ...specs };
        let barcodeZPL = '';
        switch (specsWithDefaults.type) {
            //including this switch for future extension, even though right now it might seem silly.
            //case 'CODE39'
            //    break;
            //case 'QR'
            //    break;
            case '':
            case undefined:
            case 'CODE128':
            default: {
                const {
                    orientation = 'N',
                    barcodeHeight, //41 = 0.2 inch tall bars @ 203dpi printer
                    includeHumanReadable = false,
                    humanReadableAboveBarcode = false,
                    calculateCheckDigit = false,
                    subset
                } = specsWithDefaults;
                const mode = subset ? 'N' : 'A';
                barcodeZPL = `${CODE_128_BAR_CODE}${orientation},${barcodeHeight},${includeHumanReadable ? 'Y' : 'N'},${
                    humanReadableAboveBarcode ? 'Y' : 'N'
                },${calculateCheckDigit ? 'Y' : 'N'},${mode}`;

                if (subset) {
                    switch (subset) {
                        case 'A':
                            value = `>9${value}`;
                            break;
                        case 'B':
                            value = `>:${value}`;
                            break;
                        case 'C':
                            value = `>;${value}`;
                            break;
                    }
                }
                break;
            }
        }
        zplSoFar.value =
            zplSoFar.value +
            `\n    ${BAR_CODE_FIELD_DEFAULT}${specsWithDefaults.barWidth},3,${specsWithDefaults.barcodeHeight}` + //Configures the bar code width, widthRatio, height`
            `\n    ${barcodeZPL}` + //set barcode specs
            `\n    ${FIELD_DATA}${value}` + //fill the field
            `\n${FIELD_END}`; //close the field

        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _addText(zplSoFar) {
    return function addText(value) {
        zplSoFar.value =
            zplSoFar.value +
            `\n    ${FIELD_DATA}${value}\\&` + //fill the field, end with a line end
            `\n${FIELD_END}`; //close the field
        return {
            moveTo: _moveTo(zplSoFar),
            addComment: _addComment(zplSoFar, true),
            toString: _endLabelAndSerialize(zplSoFar)
        };
    };
}

function _endLabelAndSerialize(zplSoFar) {
    return function endLabelAndSerialize() {
        zplSoFar.value = zplSoFar.value + '\n' + END_LABEL;
        return zplSoFar.value;
    };
}

function toStringNotAllowed() {
    throw new Error('Incomplete ZPL');
}

export default { createLabel };

/*
~SD30
^XA
^PR2,2,2
^PW406
^LL203
^FT68,162
    ^BY2,3,41
    ^BCN,41,Y,N,N,N
    ^FD>:barcode
^FS
^FT0,77
    ^A0N,28,28
    ^FB406,1,7,C
    ^FDtoplines toplines toplines\&
^FS
^XZ

would be generated by

zpl.createLabel(406,203)
  .moveTo(68,162)                         //coordinates are relative to top left corner
  .addBarcode('barcode', {subset: 'B'})
  .moveTo(0,77)                           //yes, we do NOT need to follow a specific order.
  .setFont(0,'N',28,28)
  .setAlignment('center')
  .addText('toplines toplines toplines')
  .toString()

*/
