import react, { useMemo, useContext, useCallback } from 'react';
import { ThemeContext } from 'styled-components';
import styled from '../styled';
import View from './View';
import ReactDOM from 'react-dom';
import fromTheme from '../fromTheme';
import useModalQueue from '../hooks/useModalQueue';
import Toast from './Toast';
import PortalHost from './contextProviders/PortalHost';
const { createElement: rc, useEffect, forwardRef } = react;

const ModalContainer = styled(View).attrs({
    name: 'modal-container'
})`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: ${fromTheme('backgroundModal')};
    overflow: hidden;
    padding: ${({ theme }) => (theme.mobile ? '0' : theme.viewMarginMore + 'px')};
`;
ModalContainer.displayName = 'ModalContainer';

// height 100% needed for scrolling in modal content
const ModalContent = styled(View).attrs({
    name: 'modal-content'
})`
    position: ${({ theme: { mobile } }) => (mobile ? 'absolute' : 'unset')};
    top: 0;
    left: 0;
    background-color: ${({ theme, fullScreen }) => (fullScreen ?? true ? theme.baseBackgroundColor : 'transparent')};
    color: white;
    flex-direction: column;
    align-items: stretch;
    font-family: Arial;
    border-radius: ${({ theme: { mobile, borderRadius } }) => (mobile ? 0 : borderRadius)};
    min-height: ${fromTheme('minModalHeight')};
    min-width: ${fromTheme('minModalWidth')};
    max-width: ${fromTheme('maxModalWidth')};
    box-shadow: ${({ theme }) =>
        theme.darkMode
            ? '8px 11px 13px -2px #000000f0, 4px 23px 39px 0px #5787a9c0'
            : '4px 4px 11px -2px #000000d0, 4px 16px 32px 4px #5787a970'};
    overflow: hidden;
    overflow-y: ${({ scrollingRequired }) => (scrollingRequired ? 'auto' : 'unset')};
    height: ${({ scrollingRequired }) => (scrollingRequired ? '100%' : 'unset')};
    flex-grow: 1;
`;
ModalContent.displayName = 'ModalContent';

const Modal = forwardRef((props, ref) => {
    const dialog = useMemo(() => document.querySelector('dialog'), []);
    const { id, jumpQueue, visible, scrollingRequired = false, onChange } = props || {};
    const theme = useContext(ThemeContext);
    const showModal = useModalQueue(visible, id, jumpQueue);

    const preventEscOrEnterClose = useCallback(e => {
        e.preventDefault();
    }, []);

    useEffect(() => {
        // Prevent the dialog from closing when the user presses the Enter or Escape key.
        dialog.addEventListener('keydown', e => {
            if (e.key === 'Enter' || e.key === 'Escape') {
                preventEscOrEnterClose(e);
            }
        });
        return () => {
            dialog.removeEventListener('keydown', preventEscOrEnterClose);
        };
    }, [dialog, preventEscOrEnterClose]);

    // When unmounting, call onChange.  Use the change type 'unmount'
    // (as opposed to 'close') to avoid causing side-effects in existing
    // code.
    useEffect(() => {
        return () => onChange?.('unmount');
    }, [onChange]);

    const { children, ...otherProps } = props;

    if (!showModal) return null;

    const forwardToast = theme.mobile;

    // prettier-ignore
    return ReactDOM.createPortal(
        rc(ModalContainer, { ref },
            rc(PortalHost, null,
                rc(ModalContent, { ...otherProps, scrollingRequired }, children),
                forwardToast && rc(Toast)
            )
        ), dialog
    );
});
Modal.displayName = 'Modal';
export default Modal;
