import { useCallback, createElement as rc, useState, createContext, useEffect, Fragment } from 'react';
import { useSelection } from './Selection';
import Tab from './Tab';
import _ArrowButton from './ArrowButton';
import { useTheme } from 'styled-components';
import CarouselContainer from './CarouselContainer';
import { fromTheme, styled, View, webOnlyStyles, textWidth } from 'lib_ui-primitives';
import useNetwork from '../../../hooks/useNetwork';

const BUTTON_WIDTH = 16;
let EmptyTabSpace = styled(View)`
    border-bottom-width: 1px;
    border-bottom-color: ${fromTheme('borderColor')};
    flex-grow: 1;
    flex-basis: 1px;
`;
EmptyTabSpace = webOnlyStyles(EmptyTabSpace)`
    border-bottom-style: solid;
`;

const ArrowButton = styled(_ArrowButton)`
    z-index: ${({ theme }) => theme.zindex.TabCarouselArrowButton};
    width: ${BUTTON_WIDTH + 'px'};
    background-color: ${fromTheme('colorScheme', 'primary')};
    border-radius: 3px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-direction: row;
    height: ${({ buttonHeight }) => buttonHeight + 'px'};
`;
const RightArrowButton = styled(ArrowButton)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;
const LeftArrowButton = styled(ArrowButton)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;
const InnerTabs = styled(View)`
    flex-grow: 1;
    align-items: flex-end;
`;
const CarouselContext = createContext();

/**
 * Render the actual clickable tab portion that changes the displayed content
 * @param {object} props
 * @returns TabCarousel component
 */
export default function TabCarousel(props) {
    // Track the left-most tab that is displayed to the user
    const [leftMost, setLeftMost] = useState(0);
    const [ready, setReady] = useState(false);
    const [selectedIndex, setSelectedIndex] = useSelection();
    const { childrenHNodes, id } = props || {};
    const [carouselWidth, setCarouselWidth] = useState(0);
    const theme = useTheme();
    const carouselHeight = theme.fontSize + theme.textPadding * 2 + 2; /*border*/

    // Avoid rendering any tabs until we can calculate their size
    const onLayout = e => {
        setCarouselWidth(e.nativeEvent.layout.width);
        setReady(true);
    };

    // Get the width of each tab
    const widths = useCalculateWidths(carouselWidth, childrenHNodes);

    // If the widths are changed and now all the tabs fit, set the leftMost tab back to 0;
    useEffect(() => {
        setLeftMost(current => {
            if (current > 0 && widths.totalWidth < widths.containerWidth) {
                return 0;
            }
            return current;
        });
    }, [widths]);

    // Determine which tabs to display depending on which is left most and how wide the tabs are.
    const displayed = useCalcDisplayedButtons(leftMost, widths);
    const widthOfDisplayed = useGetWidthOfDisplayed(leftMost, widths.childrenWidths);
    // Decide if need to display the buttons to shift the tabs/left or right.
    const displayLeftButton = leftMost > 0 && widths.totalWidth > widths.containerWidth;
    // to decide if the right button needs to be displayed,
    // We need to check if the text _without_ that button fits,
    // so allow for having an "extra" button width space.
    // If we don't do this, and the space is dynamically sized (based on the content width)
    // it starts adding, then removing, then adding, etc, the button, hanging the UI.
    const displayRightButton =
        leftMost < childrenHNodes.length - 1 && widthOfDisplayed > widths.containerWidth + BUTTON_WIDTH;

    // handlers for left and right buttons.
    const increaseLeftMost = useCallback(() => setLeftMost(i => i + 1), []);
    const decreaseLeftMost = useCallback(() => setLeftMost(i => i - 1), []);

    // This stuff is to measure the height of the buttons on the left and right sides.
    // They should be the height of the selected tab if they are right next to it.
    // Otherwise, they should be the height of the unselected tabs.
    // Depending on the zoom, this can be off by one pixel. 🤷‍♂️
    // + 2 for border and + 6 for selected height difference.
    const smallButtonHeight = theme.fontSize + theme.viewPaddingMore * 2 + 2;
    const largeButtonHeight = theme.fontSize + theme.viewPaddingMore * 2 + 2 + 6;
    const firstDisplayedTab = displayed.indexOf(true);
    const lastDisplayedTab = displayed.lastIndexOf(true);
    const leftButtonHeight = selectedIndex === firstDisplayedTab ? largeButtonHeight : smallButtonHeight;
    const rightButtonHeight = selectedIndex === lastDisplayedTab ? largeButtonHeight : smallButtonHeight;

    // prettier-ignore
    return rc(CarouselContext.Provider, { value: {} },
        rc(CarouselContainer, { onLayout },
            ready && rc(Fragment, null,
                displayLeftButton && rc(LeftArrowButton, {
                    'data-testid': `carouselButtonLeft${id}`,
                    onClick: decreaseLeftMost,
                    buttonHeight: leftButtonHeight,
                    direction: 'left'
                }),
                rc(InnerTabs, null,
                    ...childrenHNodes.map((childHNode, i) =>
                        rc(Tab, {
                            key: i,
                            firstTab: i === 0,
                            lastTab: i === childrenHNodes.length - 1,
                            childHNode,
                            tabIndex: i,
                            selected: selectedIndex === i,
                            onClick: () => setSelectedIndex(i),
                            displayed: displayed[i],
                            calcedMaxWidth: widths.childrenWidths[i] ?? 1000,
                            calcedMaxHeight: carouselHeight
                        })),
                    rc(EmptyTabSpace),
                ),
                displayRightButton && rc(RightArrowButton, {
                    onClick: increaseLeftMost,
                    buttonHeight: rightButtonHeight,
                    direction: 'right',
                    'data-testid': `carouselButtonRight${id}`
                })
            ),
        )
    );
}

function useCalculateWidths(containerWidth, children) {
    const theme = useTheme();
    const { isConnected } = useNetwork();
    const [widths, setWidths] = useState({ containerWidth, childrenWidths: [], totalWidth: 0 });
    useEffect(() => {
        async function doAsync() {
            let totalWidth = 0;
            const childrenWidths = await Promise.all(
                children.map(async child => {
                    if (child.hideWhen && child.hideWhen.includes('ONLINE') === isConnected) return 0;
                    const borderAndPadding = 2 /*borders*/ + theme.viewPaddingMore * 2; /*padding*/
                    const maxTextWidth = containerWidth - borderAndPadding - BUTTON_WIDTH * 2;
                    const width = await textWidth.measure(child.title, theme.font, theme.fontSize, maxTextWidth);
                    const childWidth = Math.ceil(width + borderAndPadding) + 2; // add two for error margin
                    totalWidth += childWidth;
                    return childWidth;
                })
            );
            setWidths({ totalWidth, childrenWidths, containerWidth });
        }
        doAsync();
    }, [containerWidth, children, theme.font, theme.fontSize, theme.viewPaddingMore, isConnected]);
    return widths;
}

function useCalcDisplayedButtons(leftMost, widths) {
    const displayed = [];
    let runningWidth = 0;
    widths.childrenWidths.forEach((tab, i) => {
        const childWidth = widths.childrenWidths[i];
        if (i < leftMost) {
            displayed.push(false);
        } else if (i === leftMost) {
            displayed.push(true);
            runningWidth += childWidth;
        } else if (runningWidth < widths.containerWidth) {
            displayed.push(true);
            runningWidth += childWidth;
        } else {
            displayed.push(false);
            runningWidth += childWidth;
        }
    });
    return displayed;
}

function useGetWidthOfDisplayed(leftMost, widths) {
    let total = 0;
    for (let i = leftMost; i < widths.length; i++) {
        total += widths[i];
    }
    return total;
}
