import React from 'react';
const rc = React.createElement;

import useEventSink from '../../hooks/useEventSink';

// Inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
// See useEventSink for the other half of this.

export const EventBoundaryContext = React.createContext();

export default function EventBoundaryProvider(props) {
    const { children, logEvents, bubbleUpEvents = true, name } = props || {};
    const parentSink = useEventSink(!bubbleUpEvents);
    const context = {
        name,
        subscriptions: {},
        logEvents,
        parentSink: bubbleUpEvents ? parentSink : null
    };
    return rc(EventBoundaryContext.Provider, { value: context }, children);
}
