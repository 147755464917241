import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import { constants, metadata, dbViews, http, network } from 'lib_ui-services';
import { format } from 'date-fns';
import notHandledHere from '../notHandledHere';
import downloadTheFile from '../exportHelpers/exportHelper';
import { addTimezoneToColumns } from '../exportHelpers/utils';

const { getDbView } = dbViews;
const { EXPORT_TYPES } = constants;
// Feature Flag for server-side export generation
const FF_SERVER_SIDE_EXPORT = 'SERVER_SIDE_EXPORT';

const _p = {
    addTimezoneToColumns,
    getDbView,
    downloadTheFile,
    EXPORT_TYPES,
    http,
    network,
    getPrettyRelationName: metadata.getPrettyRelationName,
    getRelationMetadata: metadata.getRelationMetadata,
    FF_SERVER_SIDE_EXPORT,
    checkExportConditions,
    postToServer,
    confirmExport
};

export const _private = _p;

export default {
    verb: 'willExport',
    excludedNamespaceRelations: notHandledHere.concat([{ namespace: 'report' }]),
    description: 'Prepare the export metadata',
    logic: will_export,
    onError
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 *
 * @typedef ExportDefinition
 * @property {Array} [data] OPTIONAL Array of data to use instead of using it from a view
 * @property {string} [viewName] OPTIONAL name of LokiJS view if not passing in data directly
 * @property {string} [filePrefix] OPTIONAL Title/file prefix
 * @property {Array.<{propertyName:string, label:string, dataType:string}>} columns
 */

/**
 * @param {{
 *   data: ExportDefinition;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 */
async function will_export({ data, context, dispatch }) {
    const { namespace, relation, type, featureFlags: _featureFlags, user: { allFeatureFlags } = {} } = context;
    const featureFlags = [].concat(_featureFlags).concat(allFeatureFlags);
    const relationLabel = _p.getPrettyRelationName(namespace, relation);

    // Get the navigation selection if needed (for multi-inventory exports)
    const { columns, navigationSelection } = data;

    // if this rule does not know how to export this type, skip it. (if it is blank, we'll use a default)
    if (type && !EXPORT_TYPES[type]) return;

    const filePrefix = data.filePrefix || relationLabel || `export_${relation}`;
    const timestamp = format(new Date(), "yyyyMMMdd'T'hhmmaaa");
    const fileName = `${filePrefix}_${timestamp}`;

    let exportFields = {
        title: fileName,
        fileName,
        navigationSelection,
        featureFlags,
        namespace,
        relation,
        relationLabel,
        fileType: EXPORT_TYPES[type] || EXPORT_TYPES.csv,
        filePrefix,
        columns
    };

    const countResponse = await dispatch(
        {
            criteria: { 'meta.deleted': { $exists: false } }
        },
        { verb: 'count', namespace, relation },
        true
    );
    let count = countResponse?.result ? countResponse.result[0] : 0;

    if (!count) {
        return sendNotification(`No ${relationLabel} to Export`, dispatch);
    }

    const canPerformServerSideExport = await _p.checkExportConditions(data, context, dispatch);
    if (canPerformServerSideExport) {
        // Add the timezone to the columns so that the server knows the timezone to use for the export
        const columnsWithTimezone = _p.addTimezoneToColumns(exportFields.columns);
        exportFields.columns = columnsWithTimezone;
        const response = await _p.postToServer({ data, exportFields, dispatch });
        if (response && response._id) {
            await sendNotification(`Preparing to export ${relationLabel} records`, dispatch);
            // add the reportId to the exportFields so doingExport will know how to handle the export data
            exportFields.reportId = response._id;
        } else {
            return { error: { field: {}, aborted: true }, data: {} };
        }
    } else {
        await sendNotification(`Exporting ${count} ${relationLabel} records`, dispatch);
    }

    return { data: { ...data, ...exportFields, count }, context };
}

/**
 * Posts the export request to the server.
 * This is a synchronous call to the server to create the report metadata record.
 *
 * @param {{
 *   data: ExportDefinition;
 *   exportFields: object;
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 * }} parameters
 */
async function postToServer({ data, exportFields, dispatch }) {
    // Get user confirmation before exporting
    const confirmedExport = await _p.confirmExport({ relationLabel: exportFields.relationLabel, dispatch });
    if (!confirmedExport) return;

    // get the filters from the view
    const view = await _p.getDbView(exportFields.namespace, exportFields.relation, data.viewName);
    const filters = view.viewCriteria.getFiltersFromView();

    exportFields.reportType = 'adhoc_export';

    // pass in the filters as query parameters
    const url = _p.http.getQueryUrl('report', 'generate', filters);
    const response = await _p.http.post(url, exportFields, true);
    return response;
}

/**
 * Checks if the server-side export can proceed based on certain conditions.
 * @param {ExportDefinition} data - The export data definition.
 * @param {Context} context - The context of the operation.
 * @param {function} dispatch - The dispatch function to send messages.
 * @returns {Promise<boolean>} - True if server-side export can proceed, otherwise false.
 */
async function checkExportConditions(data, context, dispatch) {
    if (!data.limitSyncSize) {
        return false;
    }

    if (!context?.user?.allFeatureFlags?.includes(_p.FF_SERVER_SIDE_EXPORT)) {
        return false;
    }

    const DISALLOWED_EXPORT_TYPES = ['pdf', 'html'];
    if (DISALLOWED_EXPORT_TYPES.includes(context.type)) {
        await sendNotification(
            `Server side report generation of ${context.type} files is not supported. Using default client side report generation.`,
            dispatch
        );
        return false;
    }

    if (!data.isConnected) {
        await sendNotification(
            'You must be online to perform a server side export. Using default client side report creation.',
            dispatch
        );
        return false;
    }

    return true;
}

/**
 * Sends a notification message to the user.
 * @param {string} message - The message to be sent.
 * @param {function} dispatch - The dispatch function to send messages.
 */
async function sendNotification(message, dispatch) {
    await dispatch(
        {
            addToList: false,
            message: message
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}

function onError({ error, dispatch }) {
    dispatch(
        {
            message: `Export failed: ${error.message}`
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    throw error;
}

async function confirmExport({ relationLabel, dispatch }) {
    return new Promise(resolve => {
        const message = [
            `This action will export your ${relationLabel} records, and could take some time to complete.`,
            'Refreshing or closing this page will interrupt the export, but you can continue to navigate within the application.',
            'Continue?'
        ];
        dispatch(
            {
                message,
                okButtonText: 'YES',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => resolve(true),
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
