export default {
    verb: 'didCreate',
    namespace: 'print',
    relation: 'job',
    description: 'close print dialog (if any)',
    logic: closeDialog
};

function closeDialog({ data, dispatch }) {
    const { result } = data || {};
    dispatch(
        {
            title: 'Label Printing',
            message: `${result.count} Label${result?.count === 1 ? '' : 's'} sent to printer.`,
            okButtonText: 'OK',
            noCancelOption: true
        },
        {
            verb: 'confirm',
            namespace: 'application',
            relation: 'user'
        }
    );
    dispatch(
        {},
        {
            verb: 'cancel',
            namespace: 'print',
            relation: 'job',
            type: 'ignore-changes'
        }
    );
}
