import lodash from 'lodash';
const { get } = lodash;
const { cloneDeep, set } = lodash;
import { metadata, constants } from 'lib_ui-services';
const { useCaseIds } = constants;
const { getPathToProperty } = metadata;

export default {
    verb: 'willChange',
    namespace: 'item',
    relation: 'item',
    useCaseIds: [useCaseIds.ASSET_TRACKING, useCaseIds.AMERICAN_WATER_ASSET_TRACKING, useCaseIds.FLAIR],
    description:
        'For Asset Tracking apps and FLAIR, adjust foundBy and foundDate when the asset is manually found or unfound',
    logic: willChange
};

async function willChange({ data, dispatch, context }) {
    let { oldRecord, propertyName, newValue, propertyPath } = data;

    // Dispatch a new change event if the asset found state is changed.
    let match;
    if (propertyName === 'found') {
        match = propertyPath.match(/inventory\.(\d+)$/);
    } else {
        match = propertyName.match(/inventory\.(\d+)\.found$/);
    }
    if (match?.length > 0) {
        const inventoryNumber = match[1];
        const fullPath = getPathToProperty(data);
        const oldValue = get(oldRecord, fullPath);
        const currentValue = get(data.newRecord, fullPath);
        // Get newRecord value with the intended change, so we can pass it with the other dispatches
        // below.  Otherwise, they will undo the original change by starting with old data.
        const newRecord = cloneDeep(data.newRecord);
        set(newRecord, fullPath, newValue);

        if (newValue === false && (oldValue === true || currentValue === true)) {
            // Changing to unfound
            // NEED TO MERGE IN CHANGES IMMEDIATELY OR THE SECOND ONE WILL OVERWRITE THE FIRST.
            const { newRecord: changedRecord0, propertiesToUnset: propertiesToUnset0 } = await dispatch(
                {
                    ...data,
                    newRecord,
                    propertyPath: `inventory.${inventoryNumber}`,
                    propertyName: 'foundDate',
                    newValue: undefined
                },
                context,
                true
            );

            const { newRecord: changedRecord1, propertiesToUnset: propertiesToUnset1 } = await dispatch(
                {
                    ...data,
                    newRecord: changedRecord0,
                    propertiesToUnset: propertiesToUnset0,
                    propertyPath: `inventory.${inventoryNumber}`,
                    propertyName: 'foundBy',
                    newValue: undefined
                },
                context,
                true
            );
            return { ...data, newRecord: changedRecord1, propertiesToUnset: propertiesToUnset1 };
        } else if (newValue === true && (oldValue === false || currentValue == false)) {
            // Changing to found
            // prefer previous found date or found by (in case user toggles back and forth).
            const previousFoundDate = get(
                oldRecord,
                getPathToProperty({ ...data, propertyName: propertyName.replace('found', 'foundDate') })
            );
            const previousFoundBy = get(
                oldRecord,
                getPathToProperty({ ...data, propertyName: propertyName.replace('found', 'foundBy') })
            );
            // NEED TO MERGE IN CHANGES IMMEDIATELY OR THE SECOND ONE WILL OVERWRITE THE FIRST.
            const { newRecord: changedRecord0, propertiesToUnset: propertiesToUnset0 } = await dispatch(
                {
                    ...data,
                    newRecord,
                    propertyPath: `inventory.${inventoryNumber}`,
                    propertyName: 'foundDate',
                    newValue: previousFoundDate || new Date().toISOString()
                },
                context,
                true
            );
            const { newRecord: changedRecord1, propertiesToUnset: propertiesToUnset1 } = await dispatch(
                {
                    ...data,
                    newRecord: changedRecord0,
                    propertiesToUnset: propertiesToUnset0,
                    propertyPath: `inventory.${inventoryNumber}`,
                    propertyName: 'foundBy',
                    newValue: previousFoundBy || { _id: context.user._id, displayName: context.user.displayName }
                },
                context,
                true
            );
            return { ...data, newRecord: changedRecord1, propertiesToUnset: propertiesToUnset1 };
        }
    }
}
