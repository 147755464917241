import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
import lodash from 'lodash';
const { cloneDeep } = lodash;
import conversions from '@sstdev/lib_epc-conversions';
import { errors } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';

const _p = {
    setDefaultValuesIfNecessary
};

export const _private = _p;

export default {
    verb: 'doingValidate',
    namespace: 'item',
    relation: 'transaction',
    description: 'prevent creating a transaction for an invalid new state',
    //type: '',
    priority: -5, // after the regular validation
    useCaseIds: [constants.useCaseIds.WISE_ID],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data, context }) => {
                const convertFromHex = context?.user?.allFeatureFlags?.includes('displayInAscii') ?? false;
                const { assetNo, tagId } = data.newRecord;
                const assetNoHex = convertFromHex ? conversions.ascii.toHex(assetNo).padEnd(20, '0') : assetNo;
                const wiseId = convertFromHex && tagId != null ? tagId.padEnd(20, '0') : tagId;
                const query = wiseId
                    ? //if we HAVE a wiseID, the value in assetNo IS the employeeId. But we also want a record back if there is one with the current wiseID
                      { $and: [{ $or: [{ assetNo }, { tagId: wiseId }] }, { 'meta.deleted': { $exists: false } }] }
                    : //IF we do NOT have a wiseID, it might be the employeeId, or the tagId
                      { $and: [{ $or: [{ assetNo }, { tagId: assetNoHex }] }, { 'meta.deleted': { $exists: false } }] };
                return query;
            }
        }
    ],
    //this is the actual logic:
    logic: doingValidate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

const defaultMessage = 'There are problems with this Transaction. For details, see the red message(s) above.';

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingValidate({ data, prerequisiteResults, dispatch, context }) {
    // Do not do wise ID specific validation on updates - this should only be the
    // Reason Code Summary page (I think). Everything else should create a new transaction.
    if (!context.isNew) {
        return;
    }

    const displayInAscii = context?.user?.allFeatureFlags?.includes('displayInAscii') ?? false;
    const [{ result: items }] = prerequisiteResults;
    const { newRecord: _newRecord } = data;
    const newRecord = cloneDeep(_newRecord);
    // Need to merge in defaults to avoid validation errors for missing required values
    // that are defaulted.
    await _p.setDefaultValuesIfNecessary(context, newRecord);

    if (!newRecord.assetNo) {
        throw new errors.ValidationError(defaultMessage, { assetNo: ['Required'] });
    }
    const statusCode = await statusFromReasonCode(newRecord, dispatch);
    const transaction = { ...newRecord, ...statusCode };
    const dbItem = extractDbItem(items, newRecord, displayInAscii) || {};
    verifyTransition(dbItem, transaction);

    verifyAssetNo(dbItem, transaction);

    verifyWiseID(transaction, displayInAscii);
}

async function statusFromReasonCode(transaction, dispatch) {
    if (!transaction['item:reasonCode']) {
        throw new errors.ValidationError(defaultMessage, { 'item:reasonCode': ['Reason Code is required'] });
    }
    const { result: [reasonCode] = [] } = await dispatch(
        { _id: transaction['item:reasonCode']._id },
        {
            verb: 'get',
            namespace: 'item',
            relation: 'reasonCode',
            type: 'get'
        },
        true
    );
    if (reasonCode) {
        return { 'item:status': reasonCode['item:status'] };
    }
    throw new errors.ValidationError('Transaction is missing a valid reason code attached to a proper status', {
        'item:reasonCode': ['Reason Code is invalid']
    });
}

/**
 *
 * @param {object} itemResult
 * @param {object[]} [itemResult.payload]
 * @returns
 */
function extractDbItem(itemResult, { assetNo }, displayInAscii) {
    const numItemsFound = itemResult && itemResult.length;
    if (numItemsFound === 0) return;

    const tagId = displayInAscii ? conversions.ascii.toHex(assetNo).padEnd(20, '0') : assetNo;

    const tagUsedByOtherItem = itemResult.some(i => i.assetNo !== assetNo && i.tagId !== tagId);
    if (tagUsedByOtherItem) {
        throw new errors.ValidationError('Unable to save transaction. WiseID already in use.', {});
    }
    //should never happen.... I think:
    if (numItemsFound > 1) {
        throw new errors.ValidationError('Unable to save transaction. Multiple employees with the same ID found.', {});
    }
    return itemResult[0];
}

/**
 * IF we have a dbItem, take its assetNo, otherwise assume this is a new employee,
 * and take the assetNo from the transaction
 * @param {*} dbItem
 * @param {*} transaction
 * @returns
 */
function verifyAssetNo(dbItem = {}, transaction) {
    const assetNo = dbItem.assetNo || transaction.assetNo;
    //assetNo should be 6 numeric characters
    if (!/^\d{6}$/.test(assetNo)) {
        throw new errors.ValidationError(defaultMessage, { assetNo: 'EmployeeID should be exactly 6 digits long' });
    }
    return assetNo;
}

/**
 * Extract and validate the tagId from the transaction
 * @param {*} transaction
 * @param {*} convertFromHex
 * @param {*} currentTagId
 * @returns tagId value, or undefined if absent on transaction
 */
function verifyWiseID(transaction, convertFromHex) {
    //IF a tagID was explicitly passed in, use that, otherwise assume one will be on the item already
    if (transaction.tagId) {
        //we DO need to validate that it is a valid tagId
        const wiseIdAscii = convertFromHex ? conversions.ascii.fromHex(transaction.tagId) : transaction.tagId;
        //wiseID is a C followed by 5 numeric characters
        if (!/^C\d{5}$/.test(wiseIdAscii)) {
            throw new errors.ValidationError('WiseID should be a `C` followed by 5 digits', {});
        }
    }
}

/**
 * check if the transition is valid, based on the current status of the dbItem
 * @param {*} dbItem
 * @param {*} transaction
 */
function verifyTransition(dbItem, transaction) {
    const currentItemStatus = dbItem['item:status'] ? dbItem['item:status'].title : undefined;
    const nextStatus = transaction['item:status'].title;
    if (['START', 'REISSUE'].includes(nextStatus) && !transaction.tagId) {
        throw new errors.ValidationError(defaultMessage, {
            tagId: ['WiseID is required']
        });
    }
    if (!['START', 'END', 'REISSUE', 'RESCHEDULE', 'PAUSE', 'RESUME', 'FORCE-RELEASE'].includes(nextStatus)) {
        throw new errors.ValidationError(defaultMessage, {
            'item:status': [`'${nextStatus}' is not a valid WiseID status`]
        });
    }
    const transition = from(currentItemStatus).to(nextStatus);
    if (!transition.isAllowed) {
        throw new errors.ValidationError(transition.error, {});
    }
}

function from(fromStatus = 'Released') {
    //there are only 3 states an item can have: checked-in, paused, or released.
    //If the item didn't exist, it will behave the same as when it was "released".
    //for each of the 3 from states, define errors for the non-allowed transitions
    const transitionErrors = {
        'Checked-in': {
            START: 'Employee already checked-in',
            //END: Allowed,
            //REISSUE: Allowed,
            //RESCHEDULE: Allowed,
            //PAUSE: Allowed,
            RESUME: 'Employee not paused, resume not needed'
            //'FORCE-RELEASE': Allowed
        },
        Released: {
            //START: Allowed,
            END: 'Employee is not checked-in',
            REISSUE: 'Employee is not checked-in',
            RESCHEDULE: 'Employee is not checked-in',
            PAUSE: 'Employee is not checked-in',
            RESUME: 'Employee is not checked-in',
            'FORCE-RELEASE': 'Employee is not checked-in'
        },
        PAUSE: {
            START: 'Employee is in paused state',
            END: 'Employee is in paused state',
            //REISSUE: Allowed,
            RESCHEDULE: 'Employee is in paused state',
            PAUSE: 'Employee is already paused'
            //RESUME: Allowed,
            //'FORCE-RELEASE': Allowed
        }
    };
    return {
        to: toStatus => {
            const error = transitionErrors[fromStatus] && transitionErrors[fromStatus][toStatus];
            return {
                isAllowed: !error,
                error
            };
        }
    };
}
