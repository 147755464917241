import './createNonce';
import './setAttributePolyfill';
import './whyDidYouRender';
import { createElement as rc, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { constants } from 'lib_ui-services';
import { App, hooks, components } from 'lib_ui-components';
import 'intro.js/introjs.css';

const EventBoundaryProvider = components.contextProviders.EventBoundary;

// Certain services (like RFID/BLE) which are specific to the device can only
// really be registered from that context.
const OuterEventBoundary = () => {
    // prettier-ignore
    return rc(EventBoundaryProvider, { bubbleUpEvents: false, name: 'WebUiBoundary' },
        rc(WebUi)
    );
};
const WebUi = () => {
    const [subscribe] = hooks.useEventSink();

    useEffect(() => {
        return subscribe(
            { verb: 'startup', namespace: 'application', relation: 'rulesEngine', status: 'success' },
            ({ eventSink }) => {
                // use the eventSink from the rulesEngine context rather than from
                // here (OuterEventBoundary is too high up in the eventSink provider hierarchy
                // and a message from there won't reach the rulesEngine).
                const [, publish] = eventSink;
                // eslint-disable-next-line no-undef
                if (!__PRODUCTION__ && __TEST_RFID__) {
                    publish(
                        {
                            type: constants.sensorTypes.RFID,
                            name: 'mockRfidService'
                        },
                        { verb: 'register', namespace: 'sensor', relation: 'service' }
                    );
                }
                publish(
                    {
                        type: constants.sensorTypes.BARCODE,
                        name: 'webBarcodeService'
                    },
                    { verb: 'register', namespace: 'sensor', relation: 'service' }
                );
            }
        );
    }, [subscribe]);

    return rc(App, null);
};

async function startup() {
    // eslint-disable-next-line no-undef
    if (__USE_XSTATE_INSPECTOR__) {
        //Create XState inspector in dev mode.
        const { inspect } = await import('@xstate/inspect');
        inspect({ iframe: false });
    }
    const root = createRoot(document.getElementById('root'));
    root.render(rc(OuterEventBoundary));
}

startup();
