import { http } from 'lib_ui-services';

export const _private = { http };

export default {
    verb: 'doingUpdate',
    namespace: 'security',
    relation: 'profile',
    description: 'update the security profile - really just used for changing the password',
    //this is the actual logic:
    logic: doingUpdate
};

async function doingUpdate({ data, dispatch }) {
    const { newRecord } = data;
    if (!newRecord) return; // not a password update.
    const password = newRecord.password ?? '';
    if (password === '') {
        throw new Error('There is no change to save, or the password and confirmation password do not match.');
    }
    await _private.http.post('/api/security/setPassword', { password });
    // Logout and redirect to login screen.
    dispatch({}, { verb: 'remove', namespace: 'security', relation: 'profile' }, true);
    // UserContext will look for an update_security_profile_success and attempt to set
    // new profile and trigger a data synchronization.
    // Create an update failure to prevent this happening before the logout redirect can complete.
    // Current rulesengine.io does not support a workflow abort without an error.
    throw new Error('Password update successful.  Logging out.');
}
