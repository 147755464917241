import { useContext, useState, useEffect, useCallback } from 'react';
import PortalContext from '../contexts/PortalContext';
import ReadContext from '../contexts/ReadContext';
import PropTypes from 'prop-types';

/**
 * Portal allows rendering a component at a different place in the parent tree.
 * You can use it to render content which should appear above other elements, similar to `Modal`.
 * It requires a PortalHost component to be rendered somewhere in the parent tree.
 * Right now PortalHost is in the lib_ui-component App.js and in lib_ui-primitive Modal.js (Modal.native.js)
 *
 * Inspired by https://github.com/callstack/react-native-paper/tree/main/src/components/Portal
 * but unable to use that component without dragging a huge amount of code into the
 * project.  Also this code is WAY more concise.
 *
 * ## Usage
 * ```js
 * import * as React from 'react';
 * import { Portal, Text } from 'lib_ui-primitives';
 *
 * const MyComponent = () => (
 *   rc(Portal, null,
 *     rc(Text, null, 'This is rendered at a different place')
 *   )
 * );
 *
 * export default MyComponent;
 * ```
 */
export default function Portal(props) {
    const { children, id, onUnmount } = props;
    const PortalManager = useContext(PortalContext);
    const readContext = useContext(ReadContext);
    const [key, setKey] = useState();
    const checkManager = useCallback(() => {
        if (!PortalManager) {
            throw new Error('Looks like you forgot to wrap your root component with a PortalHost');
        }
    }, [PortalManager]);

    useEffect(() => {
        checkManager();

        setKey(prev => {
            if (prev == null) {
                return PortalManager.mount(children, id, onUnmount, {
                    ReadContext: readContext
                });
            }
            PortalManager.update(prev, children);
            return prev;
        });
    }, [PortalManager, children, checkManager, id, onUnmount, readContext]);

    useEffect(() => {
        return () => {
            checkManager();
            PortalManager.unmount(key);
        };
    }, [checkManager, PortalManager, key]);

    return null;
}
Portal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    id: PropTypes.string.isRequired,
    onUnmount: PropTypes.func
};
