import { useEffect, createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { fromTheme, styled, View, Text, webOnlyStyles } from 'lib_ui-primitives';
import logging from '@sstdev/lib_logging';
import useDisabledForNetwork from '../../../hooks/useDisabledForNetwork';
import { useSelection } from './Selection';

/*

    border-left-color: ${({ selected, lastTab, theme: { borderColor } }) =>
        selected && lastTab ? borderColor : 'transparent'};
        */
/**
 * (At least) Two strange things here:
 * 1) border-bottom-color is behaving differently between native and web and I'm not really sure why
 * I also cannot figure out why .09 opacity is what works there for native.
 * 2) max-height is calculated as the fontSize + the padding used for the tab + border widths
 * - top border fore selected tab.  The weird thing is that it doesn't work.  It's too short.
 * I arbitrarily added 6dps/px and that made it big enough where it doesn't squish the text.
 * I could have measured the text height, but it seemed like overkill/unnecessary CPU use.
 */
let _Tab = styled(View).attrs({ name: 'tab' })`
    background-color: ${({ selected, theme }) => (selected ? theme.backgroundColor : theme.backgroundColorLighter)};
    border-top-width: ${({ selected }) => (selected ? '4px' : '1px')};
    border-top-color: ${({ selected, theme }) => (selected ? theme.colorScheme.success : theme.borderColor)};
    padding: ${({ selected, theme }) => (selected ? theme.viewPaddingMore : theme.viewPaddingMore - 2) + 'px'};
    border-bottom-width: 1px;
    border-bottom-color: ${({ selected, theme: { native, borderColor } }) =>
        selected ? (native ? 'rgba(0, 0, 0, 0.09)' : 'transparent') : borderColor};
    border-right-width: 1px;
    border-right-color: ${fromTheme('borderColor')};
    border-left-width: 1px;
    border-left-color: ${({ firstTab, theme: { borderColor } }) => (firstTab ? borderColor : 'transparent')};
    flex-grow: 0;
    flex-shrink: 0;
    max-width: ${({ calcedMaxWidth }) => calcedMaxWidth + 'px'};
    display: ${({ displayed }) => (displayed ? 'flex' : 'none')};
    max-height: ${({ selected, theme }) =>
        (selected
            ? theme.fontSize + theme.viewPaddingMore * 2 + 2 + 6
            : theme.fontSize + theme.viewPaddingMore * 2 + 2 - 4 + 6) + 'px'};
    border-top-left-radius: ${fromTheme('borderRadius')};
    border-top-right-radius: ${fromTheme('borderRadius')};
`;

_Tab = webOnlyStyles(_Tab)`
cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
border-style: solid;
`;
_Tab.displayName = 'Tab';

const DisabledTab = styled(_Tab)`
    border-top-color: ${fromTheme('backgroundColorDarker')};
    border-right-color: ${fromTheme('backgroundColorDarker')};
`;
DisabledTab.displayName = 'DisabledTab';

const DisabledText = styled(Text)`
    color: ${fromTheme('disabledFontColor')};
    white-space: nowrap;
    overflow: hidden;
`;

const RegularText = styled(Text).attrs({ name: 'regular-text' })`
    white-space: nowrap;
    overflow: hidden;
`;
RegularText.displayName = 'RegularText';

/**
 * @typedef {Object} Props
 * @property {Object} childHNode
 * @property {Function} onClick
 */
/** @type {import('react').FC<Props>} */
function Tab(props) {
    const { childHNode, onClick, tabIndex } = props || {};
    const [, , setIfIndexDisabled, setIfIndexHidden] = useSelection();
    const { disabledByNetwork, hiddenByNetwork, disabledMessage } = useDisabledForNetwork(childHNode);
    const hidden = hiddenByNetwork;

    useEffect(() => {
        if (hidden) {
            logging.debug(`[TAB] ${childHNode.title} hidden by network or platform`);
        }
        setIfIndexHidden(tabIndex, hidden);
    }, [setIfIndexHidden, hidden, childHNode, tabIndex]);

    useEffect(() => {
        if (disabledByNetwork) {
            logging.debug(`[TAB] ${childHNode.title} disabled by network`);
        }
        setIfIndexDisabled(tabIndex, disabledByNetwork);
    }, [setIfIndexDisabled, disabledByNetwork, childHNode, tabIndex]);

    if (hidden) return null;

    if (disabledByNetwork) {
        // prettier-ignore
        return rc(DisabledTab, { ...props, disabled: true, onClick: () => {} },
            rc(DisabledText, { numberOfLines: 1, ellipseMode: 'tail', title: disabledMessage, disabled: true }, childHNode.title)
        );
    }

    // prettier-ignore
    return rc(_Tab, props,
        rc(RegularText, { onClick, numberOfLines: 1, ellipseMode: 'tail' }, childHNode.title)
    );
}

Tab.defaultProps = {};

Tab.propTypes = {
    childHNode: PropTypes.object,
    onClick: PropTypes.func
};

export default Tab;
