import jsonPatch from '@sstdev/lib_isomorphic-json-patch';
import logging from '@sstdev/lib_logging';
import { errors } from 'lib_ui-primitives';
import { ObjectId, offlineResilientCommunication, metadata, globalConfig } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep, unset, isEqual } = lodash;
import notHandledHere from './notHandledHere';
import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
import trimStringWhitespaceIfNecessary from '../../../../utilities/trimStringWhitespaceIfNecessary';

const { rfc6902 } = jsonPatch;

export default {
    verb: 'willUpdate',
    excludedNamespaceRelations: notHandledHere,
    prerequisites: [],
    priority: 10,
    description: 'Prepare to create the given record on the database',
    // this is the actual logic:
    logic
};

const _p = {
    setDefaultValuesIfNecessary,
    trimStringWhitespaceIfNecessary
};
export const _private = _p;
/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context, dispatch }) {
    try {
        const {
            oldRecord: _oldRecord,
            newRecord: _newRecord = {},
            hadNewAttachments = false,
            fieldsToReplace = [],
            createTests = true
        } = data;
        const newRecord = cloneDeep(_newRecord);
        const oldRecord = cloneDeep(_oldRecord);

        // for perf, orderedSets are stored in an in-memory lokijs database and for that reason,
        // they add a $loki field which must be retained while also retaining a $loki
        // field from any relations records merged into the orderedSet's records.
        // So the merged records store the merged relation $loki value as $dbLoki.
        // This code replaces the orderedSet $loki in favor of the $dbLoki because
        // we'll need to put the updated relation record back in the database now.
        if (newRecord.$dbLoki != null) {
            newRecord.$loki = newRecord.$dbLoki;
        }

        // Add defaults and metadata
        await _p.setDefaultValuesIfNecessary(context, newRecord);
        await _p.trimStringWhitespaceIfNecessary(context, newRecord);
        const currentTime = new Date().toISOString();
        const currentUser = { _id: context.user._id, displayName: context.user.displayName };
        context.correlationId = context.correlationId || new ObjectId().toString();
        newRecord.meta = {
            ...newRecord.meta,
            modifiedBy: currentUser,
            modifiedTime: currentTime
        };
        unset(newRecord.meta, 'overriddenToDirty');
        // Create patches, exclude "meta" in addition to normal client only keys
        // as any changes made client side, should be communicated through headers,
        // not through the patch.
        const comparisonNewRecord = metadata.omitClientOnlyKeys(newRecord, ['meta']);
        const comparisonOldRecord = metadata.omitClientOnlyKeys(oldRecord, ['meta', ...fieldsToReplace]);
        // To get an update patch on the entire field to replace, set the field in oldRecord
        // to an empty value.
        setOldFieldValueToEmptyIfNecessary(comparisonOldRecord, comparisonNewRecord, fieldsToReplace);
        const patch = rfc6902.compare(comparisonOldRecord, comparisonNewRecord, createTests);

        if (patch.length === 0 && !hadNewAttachments) {
            throw new errors.ValidationError('There are no changes to save.', {});
        }

        // Create offline action (which takes care of http communication to server as well)
        const offlineAction = offlineResilientCommunication.getOfflineAction(
            newRecord.meta,
            context.correlationId,
            newRecord._id,
            { ...data, patch },
            context
        );

        return { ...data, newRecord, patch, offlineAction };
    } catch (error) {
        if (error instanceof errors.ValidationError) {
            dispatch(
                {
                    isError: true,
                    message: error.message,
                    timeout: globalConfig().notificationTimeout
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        } else if (error?.form?.[0] !== null) {
            dispatch(
                {
                    isError: true,
                    message: error.form[0],
                    timeout: globalConfig().notificationTimeout
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
            throw new Error(error.form[0]);
        } else {
            logging.error(error);
        }
        throw error;
    }
}

function setOldFieldValueToEmptyIfNecessary(oldRecord, newRecord, fieldsToReplace) {
    if (fieldsToReplace == null || !Array.isArray(fieldsToReplace)) {
        return;
    }
    fieldsToReplace.forEach(field => {
        // if new record has a value and the old record has no value, then just skip so that
        // an 'add' patch will be created .
        // if new record has no value and old record has a value, the skip so that a 'remove'
        // patch will be created.
        if (oldRecord[field] != null && newRecord[field] != null && !isEqual(oldRecord[field], newRecord[field])) {
            if (typeof newRecord[field] === 'string') {
                oldRecord[field] = '';
            } else if (typeof newRecord[field] === 'object') {
                if (Array.isArray(newRecord[field])) {
                    oldRecord[field] = [];
                } else {
                    oldRecord[field] = {};
                }
            } else {
                throw new Error(`Field type ${typeof newRecord[field]} not implemented for setFieldToEmpty.`);
            }
        }
    });
}
