import { createElement as rc, useCallback } from 'react';
import useEventSink from '../../hooks/useEventSink';
import useActiveRecord from '../../hooks/useActiveRecord';
import { Button } from 'lib_ui-primitives';
import useFormContext from '../../hooks/useFormContext';

export default function FormButton(props) {
    const {
        hNode,
        hNode: { formAction: _formAction, title, buttonStyle, id },
        ...otherProps
    } = props;
    //UI 1.x.x required  different actions for everything.
    //UI 2.x.x is more flexible, and is most powerful when using as few as possible.
    // treat "print" as "submit":
    const formAction = _formAction === 'print' ? 'submit' : _formAction;

    const context = useFormContext();
    const [, publish] = useEventSink();
    const { namespace, relation, record, type } = useActiveRecord();

    const onClick = useCallback(() => {
        publish({ record }, { verb: formAction, namespace, relation, type }, { debugDetail: 'FormButton' });
    }, [namespace, relation, record, type, publish, formAction]);

    return rc(Button, {
        id,
        hNode,
        value: title,
        buttonStyle,
        onClick,
        disabled: isDisabled(context, formAction),
        ...otherProps
    });
}

function isDisabled(formState, formAction) {
    return getDisabledActions(formState).includes(formAction);
}

const submittingDisabledActions = ['submit', 'cancel', 'new', 'edit', 'remove'];
const dirtyDisabledActions = ['submit'];
const noDisabledActions = [];
function getDisabledActions(formState) {
    if (formState.submitting) {
        return submittingDisabledActions;
    }
    if (!formState.isDirty) {
        return dirtyDisabledActions;
    }
    return noDisabledActions;
}
