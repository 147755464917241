export const TRANSPARENT_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
import logo from './logos';
import useCaseImages from './useCaseImages';
/**
 *
 * @param {'sst'|'redbeam'} brand
 * @param {'white'|'color'} style
 * @param {boolean} darkMode
 */
export async function getLogo(brand, variant, darkMode) {
    // eslint-disable-next-line no-undef
    if (__UNIT_TESTING__) return TRANSPARENT_PIXEL;
    const mode = darkMode ? 'dark' : 'light';
    const image = logo[brand][variant][mode] || logo[brand][variant];
    if (!image.image) {
        //weird peculiarity: If at least the "." is not added here
        //it complains about not being able to find the module.... :-S
        const result = await import('./' + image.path);
        image.image = result.default ?? result;
    }
    return image.image;
}

/**
 *
 * @param {string} useCase useCase name, e.g. "Item Tracking", or "Flair"
 * @returns
 */
export async function getUseCaseImage(useCase) {
    // eslint-disable-next-line no-undef
    if (__UNIT_TESTING__) return TRANSPARENT_PIXEL;
    const image = useCaseImages[useCase] || useCaseImages.default;
    if (!image.image) {
        //weird peculiarity: If at least the "." is not added here
        //it complains about not being able to find the module.... :-S
        const result = await import('./' + image.path);
        image.image = result.default ?? result;
    }
    return image.image;
}
